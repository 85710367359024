import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log(state.url);
    if (state.url === '/spotify-to-apple') {
      // check if the userToken exists, return true if it does
      if (localStorage.getItem('musicUserToken')) {
        return true;
      } else {
        // Store the attempted URL for redirecting after successful login
        //this.router.navigate(['/apple-login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }
    else if (state.url === '/apple-to-spotify') {
      // check if the userToken exists, return true if it does
      if (localStorage.getItem('spotify_token')) {
        return true;
      } else {
        // Store the attempted URL for redirecting after successful login
        //this.router.navigate(['/spotify-login'], { queryParams: { returnUrl: state.url } });
        return false;
      }

    }
    else {
      // Store the attempted URL for redirecting after successful login
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
