<div class="card mb-4">
  <div class="card-header d-flex justify-content-between align-items-center">
    <h5 class="mb-0">Log</h5>
    <button
      class="btn btn-primary btn-sm"
      (click)="exportLog()"
      [disabled]="isProcessing || !logEntries.length">
      Export Log
    </button>
  </div>

  <div class="card-body">
    <!-- Search Bar -->
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="searchTerm"
        placeholder="Search logs..." />
    </div>

    <!-- Filter Dropdown -->
    <div class="mb-3">
      <select
        class="form-select"
        [(ngModel)]="currentFilter"
        (change)="setFilter(currentFilter)">
        <option value="all">All</option>
        <option value="success">Success</option>
        <option value="error">Error</option>
        <option value="high-confidence">High Confidence</option>
        <option value="medium-confidence">Medium Confidence</option>
        <option value="low-confidence">Low Confidence</option>
      </select>
    </div>

    <!-- Log Entries Display -->
    <div class="list-group" #logEntriesContainer style="max-height: 300px; overflow-y: auto;">
      <div
        *ngFor="let entry of filteredLogEntries()"
        [ngClass]="{
          'list-group-item': true,
          'list-group-item-success': entry.success && entry.confidence >= 0.8,
          'list-group-item-warning': entry.success && entry.confidence >= 0.5 && entry.confidence < 0.8,
          'list-group-item-light': entry.success && entry.confidence < 0.5,
          'list-group-item-danger': !entry.success
        }">
        <p class="mb-1">{{ entry.timestamp | date : "medium" }}</p>
        <small>{{ entry.message }}</small>
      </div>
    </div>
  </div>
</div>
