import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthStatusService } from './auth-status.service';
import { ToastMessagesService } from '../services/toast-messages.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { AppPublicConfig } from '../interfaces/app-config.interface';
import { ExchangeTokenResponse } from './../interfaces/spotify-service.interface';
import { ConfigLoaderService } from './config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class SpotifyAuthService {
  private clientId: string | undefined;
  private clientSecret: string | undefined;
  private redirectUrl: string | undefined;
  private baseUrl: string | undefined;
  private scopes = 'playlist-modify-public playlist-modify-private user-read-private user-read-email user-library-read';
  loginSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authStatusService: AuthStatusService,
    private toastService: ToastMessagesService,
    private configLoaderService: ConfigLoaderService
  ) {
    this.initializeServiceBeforeLogin();
  }

  /**
   * Initialize the Spotify Service by loading the necessary configuration.
   */
  private async initializeServiceBeforeLogin(): Promise<void> {
    console.log('SpotifyAuthService: Initializing Service...');
    try {
      const config = await this.configLoaderService.loadConfig();
      this.setConfig(config);
    } catch (error) {
      console.error('SpotifyAuthService: Failed to load configuration', error);
      this.toastService.presentToast('Failed to load Spotify configuration. Please try again later.', 'danger');
    }
  }

  /**
   * Set the configuration from ConfigLoaderService.
   */
  private setConfig(config: AppPublicConfig): void {
    if (config.environment === 'development') {
      this.redirectUrl = config.spotifyLocalRedirectUrl; // Local environment
      this.baseUrl = config.localUrl; // Local environment
    } else if (config.environment === 'dev') {
      this.redirectUrl = config.spotifyDevRedirectUrl; // Development environment
      this.baseUrl = config.devUrl; // Development environment
    } else {
      this.redirectUrl = `${config.prodUrl}/callback`; // Production environment
      this.baseUrl = config.prodUrl; // Production environment
    }

    console.log('SpotifyAuthService: Redirect URI set to:', this.redirectUrl);
    console.log('SpotifyAuthService: Base URL set to:', this.baseUrl);
  }

  /**
   * Fetch Spotify Configuration Variables from the Backend
   * This is used to get the clientId and clientSecret.
   */
  private async getSpotifyFullConfig(): Promise<void> {
    if (!this.baseUrl) {
      console.error('SpotifyAuthService: Base URL is not set. Cannot fetch Spotify configuration.');
      return;
    }

    console.log('SpotifyAuthService: Fetching Spotify configuration from:', `${this.baseUrl}/api/spotify/spotifyconfig`);
    try {
      const response = await this.http.get<{ clientId: string; clientSecret: string, redirectUrl: string }>(`${this.baseUrl}/api/spotify/spotifyconfig`).toPromise();
      console.log('SpotifyAuthService: Successfully loaded Spotify configuration:', response);
      this.clientId = response?.clientId;
      this.clientSecret = response?.clientSecret;
      this.redirectUrl = response?.redirectUrl;

      // Check if any of the values are missing
      if (!this.clientId || !this.clientSecret || !this.redirectUrl) {
        console.error('SpotifyAuthService: Missing required Spotify configuration values:', {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          redirectUrl: this.redirectUrl
        });
        this.toastService.presentToast('Incomplete Spotify configuration. Please try again later.', 'danger');
      }
    } catch (error) {
      console.error('SpotifyAuthService: Failed to fetch Spotify configuration', error);
      this.toastService.presentToast('Failed to initialize Spotify configuration. Please try again later.', 'danger');
    }
  }

  /**
   * Login Method to Redirect User to Spotify Authorization Page
   */
  async login(): Promise<void> {
    await this.getSpotifyFullConfig();
    if (this.clientId && this.redirectUrl) {
      const url = `https://accounts.spotify.com/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectUrl)}&scope=${encodeURIComponent(this.scopes)}`;
      window.location.href = url;
    } else {
      console.error('Spotify configuration is not initialized properly. Cannot proceed with login.');
      this.toastService.presentToast('Spotify configuration is not initialized. Please try again later.', 'danger');
    }
  }

  /**
   * Handle Callback after Spotify Authorization
   */
  handleCallback(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code'];
      const isAlreadyLoggedIn = !!localStorage.getItem('spotify_token');

      if (code && !isAlreadyLoggedIn) {
        // Exchange the authorization code for an access token
        this.exchangeCodeForToken(code)
          .then(() => {
            this.loginSuccess.emit();
            this.router.navigateByUrl('/home');
            this.toastService.presentToast('Successfully logged into Spotify', 'success');
          })
          .catch((error) => {
            console.error('Error exchanging code for token:', error);
            this.toastService.presentToast('Failed to log into Spotify', 'danger');
            this.router.navigate(['/']);
          });
      } else if (isAlreadyLoggedIn) {
        this.loginSuccess.emit();
        this.router.navigateByUrl('/home');
      } else {
        console.error('Spotify login failed: No code received.');
        this.toastService.presentToast('Failed to log into Spotify', 'danger');
        this.router.navigate(['/']);
      }
    });
  }

  /**
   * Exchange Authorization Code for Access Token
   */
  private async exchangeCodeForToken(code: string): Promise<void> {
    await this.getSpotifyFullConfig();
    const body = {
      code,
      redirectUrl: this.redirectUrl,
      clientId: this.clientId,
      clientSecret: this.clientSecret
    };

    if (!this.baseUrl) {
      console.error('SpotifyAuthService: Base URL is not set. Cannot exchange authorization code.');
      throw new Error('Base URL is not set.');
    }

    await this.http.post<ExchangeTokenResponse>(`${this.baseUrl}/api/spotify/exchange-token`, body).pipe(
      map((response: ExchangeTokenResponse) => {
        const accessToken = response.access_token;
        const refreshToken = response.refresh_token;
        const expiresIn = response.expires_in;

        if (accessToken && refreshToken) {
          const expirationTime = Date.now() + (expiresIn * 1000);
          localStorage.setItem('spotify_token', accessToken);
          localStorage.setItem('spotify_refresh_token', refreshToken);
          localStorage.setItem('spotify_token_expires_at', expirationTime.toString());

          // Update login status
          this.authStatusService.updateSpotifyUserStatus(true);
        } else {
          throw new Error('Failed to retrieve Spotify tokens');
        }
      }),
      catchError(error => {
        console.error('Error exchanging code for token:', error);
        return throwError(error);
      })
    ).toPromise();
  }

  /**
   * Refresh Spotify Access Token
   */
  refreshToken(): Observable<string> {
    const refreshToken = localStorage.getItem('spotify_refresh_token');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    return this.http.post<any>(`${this.baseUrl}/api/spotify/refresh-token`, { refreshToken }).pipe(
      map(response => {
        if (response.access_token) {
          const newAccessToken = response.access_token;
          const expiresIn = response.expires_in;
          const newExpirationTime = Date.now() + (expiresIn * 1000);

          localStorage.setItem('spotify_token', newAccessToken);
          localStorage.setItem('spotify_token_expires_at', newExpirationTime.toString());

          return newAccessToken;
        } else {
          throw new Error('No access token returned');
        }
      }),
      catchError(error => {
        console.error('Error refreshing access token:', error);
        if (error.status === 400) {
          alert('Failed to refresh Spotify access token. Please log in again.');
          this.signOut();
        }
        return throwError(error);
      })
    );
  }

  getValidAccessToken(): Observable<string> {
    const expiresAtStr = localStorage.getItem('spotify_token_expires_at');
    const expiresAt = expiresAtStr ? parseInt(expiresAtStr, 10) : NaN;
    const now = Date.now();

    if (!expiresAt || isNaN(expiresAt)) {
      // Handle the case where expiration time is not available or invalid
      console.error('Token expiration time is not set or invalid.');
      return throwError('Token expiration time is not set or invalid.');
    }

    if (now >= expiresAt) {
      // Token has expired, refresh it
      console.log('Access token has expired. Refreshing...');
      return this.refreshToken();  // This returns an Observable<string>
    } else {
      // Token is still valid, return it as an Observable
      const accessToken = localStorage.getItem('spotify_token');
      if (accessToken) {
        console.log('Access token is still valid.');
        return of(accessToken);  // Wrap the access token in an Observable using `of`
      } else {
        // If the token is not found, throw an error
        console.error('Access token is not available.');
        return throwError('Access token is not available.');
      }
    }
  }

  signOut() {
    localStorage.removeItem('spotify_token');
    localStorage.removeItem('spotify_refresh_token');
    localStorage.removeItem('spotify_token_expires_at');
    this.authStatusService.updateSpotifyUserStatus(false);
    this.router.navigate(['/home']);
  }
}
