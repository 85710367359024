import { Component } from '@angular/core';
import { SpotifyService } from '../services/spotify.service';
import { PlaylistService } from '../services/playlist.service';
import { SpotifyPlaylistItem } from '../models/spotify-playlist-item.model';
import { SpotifyAuthService } from '../services/spotify-auth.service';

@Component({
  selector: 'app-spotify-playlist',
  templateUrl: './spotify-playlist.component.html',
  styleUrls: ['./spotify-playlist.component.scss']
})
export class SpotifyPlaylistComponent {
  playlistUrl: string = '';
  playlistName: string = '';
  currentItems: SpotifyPlaylistItem[] = [];
  currentPage: number = 1;
  totalItems: number = 0;
  itemsPerPage: number = 100;
  isLoading: boolean = false;  // Loading indicator

  constructor(
    private spotifyService: SpotifyService,
    private playlistService: PlaylistService,
    private spotifyAuthService: SpotifyAuthService
  ) {}

  getPlaylistId(url: string): string | null {
    const match = url.match(/playlist\/([a-zA-Z0-9]+)\b/);
    return match ? match[1] : null;
  }

  fetchPlaylist() {
    const playlistId = this.getPlaylistId(this.playlistUrl);
    if (!playlistId) {
      alert('Invalid Spotify playlist URL');
      return;
    }

    this.isLoading = true;  // Start loading indicator
    this.spotifyService.getPlaylistDetails(playlistId).subscribe({
      next: data => {
        this.playlistName = data.name;
        this.totalItems = data.tracks.total;

        const firstBatchItems = data.tracks.items.map(
          (item: any) => new SpotifyPlaylistItem(item)
        );
        this.playlistService.spotifyPlaylistItems = firstBatchItems;

        this.updateCurrentItems();
        if (this.totalItems > this.itemsPerPage) {
          this.fetchAllPlaylistItems(playlistId);
        } else {
          this.isLoading = false;  // Stop loading if all items are fetched
        }
      },
      error: error => {
        console.error('Error fetching playlist:', error);
        this.spotifyAuthService.refreshToken();
        this.isLoading = false;  // Stop loading on error
        alert('Failed to fetch playlist. Try again.');
      }
    });
  }

  fetchAllPlaylistItems(playlistId: string) {
    this.playlistService.spotifyPlaylistItems = [];
    let offset = 0;
    const fetchPromises = [];

    while (offset < this.totalItems) {
      fetchPromises.push(
        this.spotifyService.getPlaylistTracks(playlistId, this.itemsPerPage, offset).toPromise()
      );
      offset += this.itemsPerPage;
    }

    Promise.all(fetchPromises)
      .then(results => {
        results.forEach(result => {
          if (result?.items) {
            const items = result.items.map((item: any) => new SpotifyPlaylistItem(item));
            this.playlistService.spotifyPlaylistItems = [
              ...this.playlistService.spotifyPlaylistItems,
              ...items
            ];
          }
        });
        this.updateCurrentItems();
      })
      .catch(error => {
        console.error('Error fetching all playlist items:', error);
      })
      .finally(() => {
        this.isLoading = false;  // Stop loading after all requests complete
      });
  }

  updateCurrentItems() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.currentItems = this.playlistService.spotifyPlaylistItems.slice(start, end);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.updateCurrentItems();
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }
}
