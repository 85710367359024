import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-track-dialog',
  templateUrl: './confirm-track-dialog.component.html',
  styleUrls: ['./confirm-track-dialog.component.scss']
})
export class ConfirmTrackDialogComponent {
  foundTrackName: string;
  foundArtistName: string;
  foundAlbumName: string;
  originalTrackName: string;
  originalArtistName: string;
  originalAlbumName: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmTrackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.foundTrackName = data.foundTrackName;
    this.foundArtistName = data.foundArtistName;
    this.foundAlbumName = data.foundAlbumName;
    this.originalTrackName = data.originalTrackName;
    this.originalArtistName = data.originalArtistName;
    this.originalAlbumName = data.originalAlbumName;
  }

  confirm() {
    this.dialogRef.close(true);  // Pass true if user confirms the match
  }

  reject() {
    this.dialogRef.close(false);  // Pass false if user rejects the match
  }
}
