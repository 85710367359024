<!-- Navigation Bar (Desktop and Larger Screens) -->
<nav class="custom-navbar navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active-link">
            <i class="fas fa-home"></i>
            <span class="nav-text">Home</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isMusicUserLoggedIn">
          <a class="nav-link" routerLink="/spotify-to-apple" routerLinkActive="active-link">
            <i class="fa-brands fa-spotify"></i>
            <span class="nav-text">Spotify to Apple Music</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isSpotifyUserLoggedIn">
          <a class="nav-link" routerLink="/apple-to-spotify" routerLinkActive="active-link">
            <i class="fa-brands fa-apple"></i>
            <span class="nav-text">Apple Music to Spotify</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/import-favorites" routerLinkActive="active-link">
            <i class="fas fa-heart"></i>
            <span class="nav-text">Import Favorites</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/spotify-playlist" routerLinkActive="active-link">
            <i class="fa-brands fa-spotify"></i>
            <span class="nav-text">Spotify Playlists [TEST]</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/playlist-compare" routerLinkActive="active-link">
            <i class="fas fa-exchange-alt"></i>
            <span class="nav-text">Compare Playlists</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/api-testing" routerLinkActive="active-link">
            <i class="fas fa-flask"></i>
            <span class="nav-text">API Testing</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Main Content -->
<div id="main-content">
  <router-outlet></router-outlet>
</div>

<!-- Bottom Navigation Bar (Visible on Mobile Only) -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom d-lg-none">
  <div class="container-fluid justify-content-around">
    <a class="nav-link" routerLink="/home" routerLinkActive="active-link">
      <i class="fas fa-home"></i>
      <span class="nav-text">Home</span>
    </a>
    <a *ngIf="isMusicUserLoggedIn" class="nav-link" routerLink="/spotify-to-apple" routerLinkActive="active-link">
      <i class="fa-brands fa-spotify"></i>
      <span class="nav-text">Spotify to Apple</span>
    </a>
    <a *ngIf="isSpotifyUserLoggedIn" class="nav-link" routerLink="/apple-to-spotify" routerLinkActive="active-link">
      <i class="fa-brands fa-apple"></i>
      <span class="nav-text">Apple to Spotify</span>
    </a>
    <a class="nav-link" routerLink="/import-favorites" routerLinkActive="active-link">
      <i class="fas fa-heart"></i>
      <span class="nav-text">Favorites</span>
    </a>
    <a class="nav-link" routerLink="/playlist-compare" routerLinkActive="active-link">
      <i class="fas fa-exchange-alt"></i>
      <span class="nav-text">Compare</span>
    </a>
        <!-- Maybe don't need this & delete for mobile -->
    <a class="nav-link" routerLink="/api-testing" routerLinkActive="active-link">
      <i class="fas fa-flask"></i>
      <span class="nav-text">API Testing</span>
    </a>
  </div>
</nav>
