<div class="container mt-5">
  <h1 class="text-center mb-4">Spotify Playlist</h1>

  <!-- Input and Fetch Button -->
  <div class="d-flex justify-content-center mb-4">
    <input
      type="text"
      class="form-control spotify-url-input me-2"
      [(ngModel)]="playlistUrl"
      placeholder="Enter Spotify Playlist URL"
    />
    <button
      class="btn btn-success"
      (click)="fetchPlaylist()"
      [disabled]="isLoading || !playlistUrl"
    >
      <span *ngIf="!isLoading">Fetch Playlist</span>
      <span *ngIf="isLoading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </span>
    </button>
  </div>

  <!-- Pagination Controls -->
  <div *ngIf="totalItems > 0" class="d-flex justify-content-between align-items-center my-4">
    <button
      class="btn btn-secondary"
      [disabled]="currentPage === 1"
      (click)="onPageChange(currentPage - 1)"
    >
      Previous
    </button>
    <span>Page {{ currentPage }} of {{ totalPages }}</span>
    <button
      class="btn btn-secondary"
      [disabled]="currentPage === totalPages"
      (click)="onPageChange(currentPage + 1)"
    >
      Next
    </button>
  </div>

  <!-- Loading Spinner -->
  <div *ngIf="isLoading" class="text-center mt-5">
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- Playlist Items -->
  <div *ngIf="currentItems.length > 0 && !isLoading" class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
    <div *ngFor="let item of currentItems" class="col">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">{{ item.trackName }}</h5>
          <p class="card-text">
            <strong>Artist:</strong> {{ item.artists }}<br />
            <strong>Album:</strong> {{ item.albumName }}<br />
            <strong>Duration:</strong> {{ item.duration }}<br />
            <strong>Explicit:</strong> {{ item.explicit ? 'Yes' : 'No' }}<br />
            <strong>Added At:</strong> {{ item.addedAt | date: 'medium' }}
          </p>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <a [href]="item.spotifyUrl" target="_blank" class="btn btn-outline-primary btn-sm">
            Listen on Spotify
          </a>
          <a [href]="item.albumImageUrl" target="_blank" class="btn btn-outline-secondary btn-sm ms-2">
            View Album Image
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- No Items Found Message -->
  <div *ngIf="!isLoading && currentItems.length === 0" class="text-center mt-5">
    <p>No playlist items found.</p>
  </div>
</div>
