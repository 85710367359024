<div class="container landing-container text-center">
  <!-- Header Section -->
  <header class="mb-4">
    <h1 class="display-4">Welcome to Spotle</h1>
    <p class="lead">Transfer your Spotify playlists to Apple Music and vice versa easily!</p>
  </header>

  <!-- Login Card Section -->
  <div class="card unified-login-card">
    <div class="card-body">
      <h2 class="card-title">Login to Get Started</h2>
      <p class="card-text">Please login with your music accounts to start transferring your playlists.</p>

      <div class="login-buttons">
        <button *ngIf="!isAppleMusicLoggedIn" (click)="loginToAppleMusic()" class="btn btn-outline-primary btn-block">
          <i class="fa-brands fa-apple"></i> Apple Music Login
        </button>
        <button *ngIf="isAppleMusicLoggedIn" (click)="signOutAppleMusic()" class="btn btn-outline-danger btn-block">
          <i class="fa-brands fa-apple"></i> Sign Out Apple Music
        </button>

        <button *ngIf="!isSpotifyLoggedIn" (click)="loginToSpotify()" class="btn btn-outline-success btn-block">
          <i class="fa-brands fa-spotify"></i> Spotify Login
        </button>
        <button *ngIf="isSpotifyLoggedIn" (click)="signOutSpotify()" class="btn btn-outline-danger btn-block">
          <i class="fa-brands fa-spotify"></i> Sign Out Spotify
        </button>
      </div>
    </div>
  </div>

  <!-- Footer Section -->
  <footer class="footer mt-5">
    <p>Developed by <a href="https://github.com/primetime43" target="_blank">primetime43</a></p>
  </footer>
</div>
