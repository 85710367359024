import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpotifyAuthService } from '../services/spotify-auth.service';
import { SpotifyService } from '../services/spotify.service';

@Component({
  selector: 'app-spotify-login',
  templateUrl: './spotify-login.component.html',
  styleUrls: ['./spotify-login.component.scss']
})
export class SpotifyLoginComponent implements OnInit {
  userProfile: any;
  playlists: any;

  constructor(
    private spotifyAuthService: SpotifyAuthService,
    private spotifyService: SpotifyService,
    private router: Router
  ) { }

  ngOnInit() {
    this.spotifyAuthService.handleCallback();
    this.spotifyAuthService.loginSuccess.subscribe(() => {
      this.loadUserProfile();
      this.loadPlaylists();
    });
  }

  login() {
    this.spotifyAuthService.login();
  }

  loadUserProfile() {
    this.spotifyService.loadUserProfile().subscribe(profile => {
      this.userProfile = profile;
    }, error => {
      console.error('Error loading user profile:', error);
    });
  }

  loadPlaylists() {
    this.spotifyService.loadPlaylists().subscribe(playlists => {
      this.playlists = playlists;
    }, error => {
      console.error('Error loading playlists:', error);
    });
  }
}
