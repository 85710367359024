import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthStatusService {
  private musicUserLoggedIn = new BehaviorSubject<boolean>(!!localStorage.getItem('musicUserToken'));
  private spotifyUserLoggedIn = new BehaviorSubject<boolean>(!!localStorage.getItem('spotify_token'));

  isMusicUserLoggedIn$ = this.musicUserLoggedIn.asObservable();
  isSpotifyUserLoggedIn$ = this.spotifyUserLoggedIn.asObservable();

  updateMusicUserStatus(status: boolean) {
    this.musicUserLoggedIn.next(status);
  }

  updateSpotifyUserStatus(status: boolean) {
    this.spotifyUserLoggedIn.next(status);
  }
}
