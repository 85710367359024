import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppPublicConfig } from './../interfaces/app-config.interface';
import { filter, take, tap, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private configSubject = new BehaviorSubject<AppPublicConfig | null>(null);
  config$ = this.configSubject.asObservable();
  private apiUrl: string = environment.apiUrl; // Initialized with default value from environment
  private baseUrl: string = environment.apiUrl; // Default baseUrl (can be updated)

  constructor(private http: HttpClient) {}

  /**
   * Load the application configuration from the API.
   */
  loadAppConfig(): Observable<AppPublicConfig> {
    const configApiURL = `${this.apiUrl}/getPublicConfig`;

    console.log('ConfigLoaderService: Attempting to load application config from:', configApiURL);

    return this.http.get<AppPublicConfig>(configApiURL).pipe(
      tap((config) => {
        console.log('ConfigLoaderService: Successfully loaded config:', config);
        this.configSubject.next(config);
        this.saveConfigToLocalStorage(config);

        // Update apiUrl and baseUrl after successfully loading config
        if (config.apiUrl) {
          this.apiUrl = config.apiUrl;
          console.log('ConfigLoaderService: Updated apiUrl to:', this.apiUrl);
        }

        console.log('ConfigLoaderService: Updated baseUrl to:', this.baseUrl);
      }),
      catchError((error) => {
        console.error('ConfigLoaderService: Error occurred while loading config:', error);
        throw error;
      })
    );
  }

  /**
   * Get the current configuration value.
   */
  getConfig(): AppPublicConfig | null {
    const currentConfig = this.configSubject.getValue();
    console.log('ConfigLoaderService: Current config value:', currentConfig);
    return currentConfig;
  }

  /**
   * Load configuration from localStorage.
   */
  loadConfigFromLocalStorage(): AppPublicConfig | null {
    const configString = localStorage.getItem('app_base_config');
    return configString ? JSON.parse(configString) : null;
  }

  /**
   * Save configuration to localStorage.
   */
  saveConfigToLocalStorage(config: AppPublicConfig): void {
    localStorage.setItem('app_base_config', JSON.stringify(config));
  }

  /**
   * Load the configuration from localStorage or fallback to the API.
   */
  async loadConfig(): Promise<AppPublicConfig> {
    // Attempt to load from localStorage
    const cachedConfig = this.loadConfigFromLocalStorage();
    if (cachedConfig) {
      console.log('ConfigLoaderService: Loaded configuration from localStorage');
      this.apiUrl = cachedConfig.apiUrl; // Set the apiUrl from cached config
      return cachedConfig;
    }

    // Attempt to load from the API via configSubject
    console.log('ConfigLoaderService: Waiting for configuration from API...');
    const config = await this.config$
      .pipe(
        filter((config): config is AppPublicConfig => config !== null),
        take(1)
      )
      .toPromise();

    if (config) {
      console.log('ConfigLoaderService: Loaded configuration from API:', config);
      return config;
    } else {
      throw new Error('ConfigLoaderService: Failed to load configuration from API or localStorage.');
    }
  }

  /**
   * Get the current apiUrl.
   */
  getApiUrl(): string {
    if (!this.apiUrl) {
      throw new Error('ConfigLoaderService: API URL is not set. Ensure configuration is loaded.');
    }
    return this.apiUrl;
  }

  /**
   * Get the current baseUrl.
   */
  getBaseUrl(): string {
    if (!this.baseUrl) {
      throw new Error('ConfigLoaderService: Base URL is not set. Ensure configuration is loaded.');
    }
    return this.baseUrl;
  }
}
