import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMessagesService {
  private toastQueue: Array<{ message: string, color: string, duration?: number, isPersistent?: boolean, textAlignment?: string }> = [];
  private isToastActive = false;

  constructor(private toastController: ToastController) { }

  async presentToast(message: string, color: string, duration: number = 3000, isPersistent: boolean = false, textAlignment: 'left' | 'center' | 'right' = 'center') {
    // Add the new toast to the queue
    this.toastQueue.push({ message, color, duration, isPersistent });
    // If no toast is currently being displayed, show the next one in the queue
    if (!this.isToastActive) {
      this.showNextToast();
    }
  }

  private async showNextToast() {
    if (this.toastQueue.length > 0) {
      this.isToastActive = true;
      const { message, color, duration, isPersistent, textAlignment } = this.toastQueue.shift()!; // Get the next toast

      const cssClass = [
        {
          success: 'toast-success',
          danger: 'toast-danger',
          warning: 'toast-warning',
          info: 'toast-info'
        }[color] || 'toast-success', // Default to success if color is not recognized
        `toast-align-${textAlignment}` // Dynamic class for text alignment
      ];

      // Create the toast options
      const toastOptions: any = {
        message: message,
        position: 'bottom',
        cssClass: cssClass,
        buttons: [
          {
            text: 'Close',
            role: 'cancel' // Adding a close button for the user to dismiss the toast
          }
        ]
      };

      // If it's not a persistent toast, set the duration
      if (!isPersistent) {
        toastOptions.duration = duration;
      }

      // Create and present the toast
      const toast = await this.toastController.create(toastOptions);

      toast.onDidDismiss().then(() => {
        this.isToastActive = false;
        this.showNextToast(); // Show the next toast in the queue
      });

      await toast.present();
    }
  }
}

// Temporary Toast
// this.toastMessagesService.presentToast('This will disappear after 3 seconds', 'success');

// Persistent Toast
// this.toastMessagesService.presentToast('This will stay until you close it', 'info', 0, true);

