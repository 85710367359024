export class ApplePlaylistItem {
  trackName: string;
  artists: string;
  albumName: string;
  duration: string;
  explicit: boolean;
  releaseDate: string;
  appleMusicUrl: string;
  albumImageUrl: string;
  trackNumber: number;
  ISRC: string;

  constructor(data: any) {
    this.trackName = data.name || 'Unknown';
    this.artists = data.artistName || 'Unknown';
    this.albumName = data.albumName || 'Unknown';
    this.duration = this.getDuration(data.durationInMillis);
    this.explicit = data.contentRating === 'explicit';
    this.releaseDate = data.releaseDate;
    this.appleMusicUrl = data.url;
    this.albumImageUrl = data.artwork?.url.replace('{w}', '300').replace('{h}', '300') || '';
    this.trackNumber = data.trackNumber;
    this.ISRC = data.isrc || '';
  }

  private getDuration(durationMs: number): string {
    const minutes = Math.floor(durationMs / 60000);
    const seconds = ((durationMs % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.length < 2 ? '0' : ''}${seconds}`;
  }
}
