import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppleMusicCatalogService } from '../services/apple-music-catalog.service';
import { AppleMusicLibraryService } from '../services/apple-music-library.service';
import { PlaylistService } from '../services/playlist.service';
import { SpotifyService } from '../services/spotify.service';
import { SpotifyPlaylistItem } from '../models/spotify-playlist-item.model';
import { SpotifyAuthService } from '../services/spotify-auth.service';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlaylistComponent } from '../shared/playlist/playlist.component';
import { ToastMessagesService } from '../services/toast-messages.service';

@Component({
  selector: 'app-spotify-to-apple',
  templateUrl: './spotify-to-apple.component.html',
  styleUrls: ['./spotify-to-apple.component.scss']
})
export class SpotifyToAppleComponent extends PlaylistComponent implements OnInit, OnDestroy {

  isFetching: boolean = false;  // Flag to indicate fetching state
  isCreating: boolean = false;  // Flag to indicate creating state
  askForTrackConfirmation: boolean = false;
  playlistItemsSubscription: Subscription | undefined;

  constructor(
    private appleMusicCatalogService: AppleMusicCatalogService,
    private appleMusicLibraryService: AppleMusicLibraryService,
    public playlistService: PlaylistService,
    private spotifyService: SpotifyService,
    private cdr: ChangeDetectorRef,
    private spotifyAuthService: SpotifyAuthService,
    private toastService: ToastMessagesService
  ) {
    super();
  }

  ngOnInit() {
    this.playlistItemsSubscription = this.playlistService.spotifyPlaylistItems$.subscribe(() => {
      this.updateCurrentItems();
      this.cdr.detectChanges();
    });
    //this.scrollToBottom();
  }

  ngOnDestroy() {
    if (this.playlistItemsSubscription) {
      this.playlistItemsSubscription.unsubscribe();
    }
  }

  getPlaylistItemsObservable() {
    return this.playlistService.spotifyPlaylistItems$;
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }

  updateCurrentItems() {
    this.filteredItems = this.playlistService.spotifyPlaylistItems.filter(item =>
      item.trackName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.artists.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.albumName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.currentItems = this.filteredItems.slice(start, end);
  }

  async createPlaylist() {
    this.isProcessing = true;
    this.isCreating = true;  // Indicate that the creation process has started

    try {
      if (this.playlistName) {
        this.showLog = true;
        const result = await this.appleMusicLibraryService.createPlaylist(this.playlistName);

        if (result.success && result.playlistId) {
          const playlistId = result.playlistId;
          this.processedItems = 0;
          const totalItems = this.playlistService.spotifyPlaylistItems.length;

          for (const item of this.playlistService.spotifyPlaylistItems) {
            try {
              const trackResult = await this.appleMusicCatalogService.searchTrack(item.trackName,item.artists,item.albumName,item.explicit,item.trackNumber,this.askForTrackConfirmation);

              if (trackResult && trackResult.track.id) {
                const trackId = trackResult.track.id;
                const confidence = trackResult.confidence;

                const addResult = await this.appleMusicLibraryService.addTrackToPlaylist(playlistId, trackId);

                if (addResult) {
                  this.addLog(`Successfully added track: ${item.trackName} by ${item.artists} to playlist: ${this.playlistName} (Confidence: ${confidence.toFixed(2)})`, true, confidence, item.trackName, item.artists, item.albumName);
                } else {
                  this.addLog(`Failed to add track: ${item.trackName} by ${item.artists} to playlist: ${this.playlistName} (Confidence: ${confidence.toFixed(2)})`, false, confidence, item.trackName, item.artists, item.albumName);
                }
              } else {
                this.addLog(`Track not found: ${item.trackName} by ${item.artists} for playlist: ${this.playlistName}`, false, 0, item.trackName, item.artists, item.albumName);
              }
            } catch (trackError) {
              this.addLog(`Error processing track: ${item.trackName} by ${item.artists} for playlist: ${this.playlistName}`, false, 0, item.trackName, item.artists, item.albumName);
            }

            this.processedItems++;
            this.progress = Math.round((this.processedItems / totalItems) * 100); // Round to whole number
          }
        } else {
          this.toastService.presentToast(`Failed to create playlist ${this.playlistName}!`, 'danger', 0, true);
        }
      } else {
        this.toastService.presentToast(`Playlist name is required to create a playlist.`, 'danger');
      }
    } catch (error) {
      this.toastService.presentToast(`Failed to create playlist ${this.playlistName}!`, 'danger', 0, true);
    } finally {
      this.isCreating = false;  // Indicate that the creation process has ended
      this.isProcessing = false;

      this.toastService.presentToast(`Playlist ${this.playlistName} successfully created on Apple Music!`, 'success', 0, true);
    }
}

  getPlaylistId(url: string): string | null {
    const match = url.match(/playlist\/([a-zA-Z0-9]+)\b/);
    return match ? match[1] : null;
  }

  fetchAllPlaylistItems(playlistId: string) {
    this.playlistService.spotifyPlaylistItems = [];
    let offset = 0;
    const fetchPromises = [];

    while (offset < this.totalItems || this.totalItems === 0) {
      fetchPromises.push(this.spotifyService.getPlaylistTracks(playlistId, this.itemsPerPage, offset).toPromise());
      offset += this.itemsPerPage;
    }

    Promise.all(fetchPromises).then(results => {
      results.forEach(result => {
        if (result && result.items) {
          const fetchedItems = result.items.map((item: any) => new SpotifyPlaylistItem(item));
          this.playlistService.spotifyPlaylistItems = this.playlistService.spotifyPlaylistItems.concat(fetchedItems);
        }
      });
      this.updateCurrentItems();
    }).catch(error => {
      console.error('Error fetching all playlist items:', error);
    });
  }

  fetchPlaylist() {
    this.resetState();
    const playlistId = this.getPlaylistId(this.playlistUrl);
    if (playlistId) {
      this.isFetching = true;
      this.spotifyService.getPlaylistDetails(playlistId).subscribe({
        next: data => {
          this.playlistName = data.name;
          this.totalItems = data.tracks.total;
          const firstBatchItems = data.tracks.items.map((item: any) => new SpotifyPlaylistItem(item));
          this.playlistService.spotifyPlaylistItems = firstBatchItems;
          this.updateCurrentItems();
          this.isPlaylistFetched = true; // Set to true after successfully fetching the playlist
          if (this.totalItems > this.itemsPerPage) {
            this.fetchAllPlaylistItems(playlistId);
          }
        },
        error: error => {
          console.error('Error fetching playlist:', error);
          this.spotifyAuthService.refreshToken();
          this.toastService.presentToast(`Failed to fetch playlist. Try again.`, 'danger', 0, true);
        },
        complete: () => {
          this.isFetching = false;
        }
      });
    } else {
      this.toastService.presentToast('Invalid Spotify playlist URL.', 'danger', 3000, false, 'left');

    }
  }
}
