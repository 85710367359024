// src/app/api-endpoint-testing/api-endpoint-testing.component.ts

import { Component, OnInit } from '@angular/core';
import { AppleMusicCatalogService } from '../services/apple-music-catalog.service';
import { AppleMusicLibraryService } from '../services/apple-music-library.service'; // Ensure this service exists
import { SpotifyService } from '../services/spotify.service'; // Ensure this service exists
import { AppleMusicCatalogSong } from '../interfaces/apple-music-catalog/apple-music-catalog-song-response.interface';
import { AppleMusicCatalogPlaylist, AppleMusicCatalogPlaylistTrack } from '../interfaces/apple-music-playlists/apple-music-playlist-response.interface';
import { AppleMusicLibraryPlaylistTrack, LibraryPlaylistTracksResponse } from '../interfaces/apple-music-library/apple-music-library-playlist-tracks.interface';
import { UtilsService } from '../shared/utils.service';

interface UserPlaylist {
  id: string;
  type: string;
  href: string;
  attributes: {
    name: string;
    description: string;
    artwork: {
      url: string;
      width: number;
      height: number;
    };
    trackCount: number;
    // ... other attributes
  };
  relationships: {
    tracks: {
      href: string;
      data: any[];
    };
  };
}

@Component({
  selector: 'app-api-endpoint-testing',
  templateUrl: './api-endpoint-testing.component.html',
  styleUrls: ['./api-endpoint-testing.component.scss']
})
export class ApiEndpointTestingComponent implements OnInit {
  // **Apple Music State Variables**
  storefrontSong: string = 'us';
  songId: string = '';
  songData: AppleMusicCatalogSong | null = null;
  songError: string | null = null;
  isLoadingSong: boolean = false;

  storefrontPlaylist: string = 'us';
  playlistId: string = '';
  playlistData: AppleMusicCatalogPlaylist | null = null;
  playlistError: string | null = null;
  isLoadingPlaylist: boolean = false;

  storefrontTracks: string = 'us';
  tracksPlaylistId: string = '';
  tracksLimit: number = 25;
  tracksOffset: number = 0;
  tracksData: AppleMusicCatalogPlaylistTrack[] = [];
  tracksError: string | null = null;
  isLoadingTracks: boolean = false;

  // **Apple Music Library State Variables**
  newPlaylistName: string = '';
  createPlaylistSuccess: boolean = false;
  createPlaylistError: string | null = null;
  isCreatingPlaylist: boolean = false;
  createdPlaylistId: string | null = null;

  addTrackPlaylistId: string = '';
  addTrackId: string = '';
  addTrackSuccess: boolean = false;
  addTrackError: string | null = null;
  isAddingTrack: boolean = false;

  userPlaylists: UserPlaylist[] = [];
  isLoadingUserPlaylists: boolean = false;
  userPlaylistsError: string | null = null;

  libraryPlaylistId: string = '';
  libraryPlaylistData: any | null = null; // Replace 'any' with a specific interface if available
  libraryPlaylistError: string | null = null;
  isLoadingLibraryPlaylist: boolean = false;

  libraryTracksPlaylistId: string = '';
  libraryTracksLimit: number = 25;
  libraryTracksOffset: number = 0;
  libraryTracksData: AppleMusicLibraryPlaylistTrack[] = [];
  libraryTracksError: string | null = null;
  isLoadingLibraryTracks: boolean = false;

  // **Spotify State Variables**
  // Get Playlist Details
  spotifyPlaylistId: string = '';
  spotifyPlaylistData: any | null = null; // Replace 'any' with a specific interface if available
  spotifyPlaylistError: string | null = null;
  isLoadingSpotifyPlaylist: boolean = false;

  // Get Playlist Tracks
  spotifyTracksPlaylistId: string = '';
  spotifyTracksLimit: number = 25;
  spotifyTracksOffset: number = 0;
  spotifyTracksData: any[] = []; // Replace 'any' with a specific interface if available
  spotifyTracksError: string | null = null;
  isLoadingSpotifyTracks: boolean = false;

  // Create Spotify Playlist
  spotifyNewPlaylistName: string = '';
  spotifyCreatePlaylistSuccess: boolean = false;
  spotifyCreatePlaylistError: string | null = null;
  isCreatingSpotifyPlaylist: boolean = false;
  spotifyCreatedPlaylistId: string | null = null;

  // Add Track to Spotify Playlist
  spotifyAddTrackPlaylistId: string = '';
  spotifyAddTrackId: string = '';
  spotifyAddTrackSuccess: boolean = false;
  spotifyAddTrackError: string | null = null;
  isAddingSpotifyTrack: boolean = false;

  // Get Spotify User Playlists
  spotifyUserPlaylists: any[] = []; // Replace 'any' with a specific interface if available
  isLoadingSpotifyUserPlaylists: boolean = false;
  spotifyUserPlaylistsError: string | null = null;

  // **Spotify Get Track State Variables**
  spotifyTrackId: string = '';
  spotifyTrackData: any | null = null; // Replace 'any' with a specific interface if available
  spotifyTrackError: string | null = null;
  isLoadingSpotifyTrack: boolean = false;

  // **State Variables for Expand/Collapse**
  isAppleMusicExpanded: boolean = true; // Default to expanded
  isSpotifyExpanded: boolean = false; // Default to collapsed

  constructor(
    private appleCatalogService: AppleMusicCatalogService,
    private appleLibraryService: AppleMusicLibraryService,
    private spotifyService: SpotifyService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    // Initialization logic if needed
  }

  /**
   * Toggle the visibility of the Apple Music API Testing section.
   */
  toggleAppleMusicSection(): void {
    this.isAppleMusicExpanded = !this.isAppleMusicExpanded;
  }

  /**
   * Toggle the visibility of the Spotify API Testing section.
   */
  toggleSpotifySection(): void {
    this.isSpotifyExpanded = !this.isSpotifyExpanded;
  }

  /**
   * Fetches a catalog song based on the provided storefront and song ID.
   */
  fetchCatalogSong(): void {
    this.isLoadingSong = true;
    this.songData = null;
    this.songError = null;

    if (!this.songId.trim()) {
      this.songError = 'Please provide a valid Song ID.';
      this.isLoadingSong = false;
      return;
    }

    this.appleCatalogService.getCatalogSong(this.songId.trim()).subscribe(
      (song: AppleMusicCatalogSong) => {
        this.songData = song;
        this.isLoadingSong = false;
      },
      (error) => {
        console.error('Error fetching catalog song:', error);
        this.songError = 'Failed to fetch song details. Please check the Song ID and try again.';
        this.isLoadingSong = false;
      }
    );
  }

  /**
   * Fetches catalog playlist details based on the provided storefront and playlist ID.
   */
  fetchCatalogPlaylistDetails(): void {
    this.isLoadingPlaylist = true;
    this.playlistData = null;
    this.playlistError = null;

    if (!this.playlistId.trim()) {
      this.playlistError = 'Please provide a valid Playlist ID.';
      this.isLoadingPlaylist = false;
      return;
    }

    this.appleCatalogService.getCatalogPlaylistDetails(this.playlistId.trim(), this.storefrontPlaylist).subscribe(
      (playlist: AppleMusicCatalogPlaylist) => {
        this.playlistData = playlist;
        this.isLoadingPlaylist = false;
      },
      (error) => {
        console.error('Error fetching catalog playlist:', error);
        this.playlistError = 'Failed to fetch playlist details. Please check the Playlist ID and try again.';
        this.isLoadingPlaylist = false;
      }
    );
  }

  /**
   * Fetches tracks from a catalog playlist based on the provided storefront, playlist ID, limit, and offset.
   */
  fetchCatalogPlaylistTracks(): void {
    this.isLoadingTracks = true;
    this.tracksData = [];
    this.tracksError = null;

    if (!this.tracksPlaylistId.trim()) {
      this.tracksError = 'Please provide a valid Playlist ID.';
      this.isLoadingTracks = false;
      return;
    }

    this.appleCatalogService.getCatalogPlaylistTracks(
      this.tracksPlaylistId.trim(),
      this.tracksLimit,
      this.tracksOffset,
      this.storefrontTracks
    ).subscribe(
      (tracks: { data: AppleMusicCatalogPlaylistTrack[] }) => {
        this.tracksData = tracks?.data;
        this.isLoadingTracks = false;
      },
      (error) => {
        console.error('Error fetching playlist tracks:', error);
        this.tracksError = 'Failed to fetch playlist tracks. Please check the Playlist ID and try again.';
        this.isLoadingTracks = false;
      }
    );
  }

  /**
   * Creates a new playlist in the user's Apple Music library.
   */
  async createNewPlaylist(): Promise<void> {
    this.isCreatingPlaylist = true;
    this.createPlaylistSuccess = false;
    this.createPlaylistError = null;
    this.createdPlaylistId = null;

    if (!this.newPlaylistName.trim()) {
      this.createPlaylistError = 'Please provide a valid Playlist Name.';
      this.isCreatingPlaylist = false;
      return;
    }

    const result = await this.appleLibraryService.createPlaylist(this.newPlaylistName.trim());

    if (result.success && result.playlistId) {
      this.createPlaylistSuccess = true;
      this.createdPlaylistId = result.playlistId;
      this.newPlaylistName = '';
    } else {
      this.createPlaylistError = 'Failed to create playlist. Please try again.';
    }

    this.isCreatingPlaylist = false;
  }

  /**
   * Adds a track to a specific playlist.
   */
  async addTrackToPlaylist(): Promise<void> {
    this.isAddingTrack = true;
    this.addTrackSuccess = false;
    this.addTrackError = null;

    if (!this.addTrackPlaylistId.trim() || !this.addTrackId.trim()) {
      this.addTrackError = 'Please provide both Playlist ID and Track ID.';
      this.isAddingTrack = false;
      return;
    }

    const success = await this.appleLibraryService.addTrackToPlaylist(this.addTrackPlaylistId.trim(), this.addTrackId.trim());

    if (success) {
      this.addTrackSuccess = true;
      this.addTrackPlaylistId = '';
      this.addTrackId = '';
    } else {
      this.addTrackError = 'Failed to add track to playlist. Please try again.';
    }

    this.isAddingTrack = false;
  }

  /**
   * Retrieves the user's playlists from their Apple Music library.
   */
  getUserPlaylists(): void {
    this.isLoadingUserPlaylists = true;
    this.userPlaylists = [];
    this.userPlaylistsError = null;

    this.appleLibraryService.getUserPlaylists().subscribe(
      (response: any) => {
        if (response && response.data) {
          this.userPlaylists = response.data as UserPlaylist[];
        }
        this.isLoadingUserPlaylists = false;
      },
      (error) => {
        console.error('Error fetching user playlists:', error);
        this.userPlaylistsError = 'Failed to fetch user playlists. Please try again.';
        this.isLoadingUserPlaylists = false;
      }
    );
  }

  /**
   * Retrieves details of a specific playlist from the user's Apple Music library.
   */
  getLibraryPlaylistDetails(): void {
    this.isLoadingLibraryPlaylist = true;
    this.libraryPlaylistData = null;
    this.libraryPlaylistError = null;

    if (!this.libraryPlaylistId.trim()) {
      this.libraryPlaylistError = 'Please provide a valid Playlist ID.';
      this.isLoadingLibraryPlaylist = false;
      return;
    }

    this.appleLibraryService.getLibraryPlaylistDetails(this.libraryPlaylistId.trim()).subscribe(
      (data: any) => {
        this.libraryPlaylistData = data;
        this.isLoadingLibraryPlaylist = false;
      },
      (error) => {
        console.error('Error fetching library playlist details:', error);
        this.libraryPlaylistError = 'Failed to fetch library playlist details. Please check the Playlist ID and try again.';
        this.isLoadingLibraryPlaylist = false;
      }
    );
  }

  /**
   * Fetches tracks from a specific playlist in the user's Apple Music library.
   */
  fetchLibraryPlaylistTracks(): void {
    this.isLoadingLibraryTracks = true;
    this.libraryTracksData = [];
    this.libraryTracksError = null;

    if (!this.libraryTracksPlaylistId.trim()) {
      this.libraryTracksError = 'Please provide a valid Playlist ID.';
      this.isLoadingLibraryTracks = false;
      return;
    }

    this.appleLibraryService.getLibraryPlaylistTracks(
      this.libraryTracksPlaylistId.trim(),
      this.libraryTracksLimit,
      this.libraryTracksOffset
    ).subscribe(
      (response: LibraryPlaylistTracksResponse) => {
        this.libraryTracksData = response.data; // Correctly assign the data array
        this.isLoadingLibraryTracks = false;
      },
      (error) => {
        console.error('Error fetching library playlist tracks:', error);
        this.libraryTracksError = 'Failed to fetch library playlist tracks. Please check the Playlist ID and try again.';
        this.isLoadingLibraryTracks = false;
      }
    );
  }

  /**
   * Formats the artwork URL by replacing placeholders with actual dimensions.
   * @param urlTemplate The artwork URL template.
   * @param width The desired width.
   * @param height The desired height.
   * @returns The formatted artwork URL.
   */
  getFormattedArtworkUrl(urlTemplate: string, width: number = 300, height: number = 300): string {
    return this.utilsService.formatImageUrl(urlTemplate, width, height);
  }

  // **Spotify API Methods**

  /**
   * Fetches playlist details from Spotify based on the provided playlist ID.
   */
  fetchSpotifyPlaylistDetails(): void {
    this.isLoadingSpotifyPlaylist = true;
    this.spotifyPlaylistData = null;
    this.spotifyPlaylistError = null;

    if (!this.spotifyPlaylistId.trim()) {
      this.spotifyPlaylistError = 'Please provide a valid Playlist ID.';
      this.isLoadingSpotifyPlaylist = false;
      return;
    }

    this.spotifyService.getPlaylistDetails(this.spotifyPlaylistId.trim()).subscribe(
      (playlist: any) => {
        this.spotifyPlaylistData = playlist;
        this.isLoadingSpotifyPlaylist = false;
      },
      (error) => {
        console.error('Error fetching Spotify playlist details:', error);
        this.spotifyPlaylistError = 'Failed to fetch Spotify playlist details. Please check the Playlist ID and try again.';
        this.isLoadingSpotifyPlaylist = false;
      }
    );
  }

  /**
   * Fetches tracks from a Spotify playlist based on the provided playlist ID, limit, and offset.
   */
  fetchSpotifyPlaylistTracks(): void {
    this.isLoadingSpotifyTracks = true;
    this.spotifyTracksData = [];
    this.spotifyTracksError = null;

    if (!this.spotifyTracksPlaylistId.trim()) {
      this.spotifyTracksError = 'Please provide a valid Playlist ID.';
      this.isLoadingSpotifyTracks = false;
      return;
    }

    this.spotifyService.getPlaylistTracks(
      this.spotifyTracksPlaylistId.trim(),
      this.spotifyTracksLimit,
      this.spotifyTracksOffset
    ).subscribe(
      (tracks: any) => {
        this.spotifyTracksData = tracks.items;
        this.isLoadingSpotifyTracks = false;
      },
      (error) => {
        console.error('Error fetching Spotify playlist tracks:', error);
        this.spotifyTracksError = 'Failed to fetch Spotify playlist tracks. Please check the Playlist ID and try again.';
        this.isLoadingSpotifyTracks = false;
      }
    );
  }

  /**
   * Creates a new Spotify playlist with the provided name.
   */
  async createNewSpotifyPlaylist(): Promise<void> {
    this.isCreatingSpotifyPlaylist = true;
    this.spotifyCreatePlaylistSuccess = false;
    this.spotifyCreatePlaylistError = null;
    this.spotifyCreatedPlaylistId = null;

    if (!this.spotifyNewPlaylistName.trim()) {
      this.spotifyCreatePlaylistError = 'Please provide a valid Playlist Name.';
      this.isCreatingSpotifyPlaylist = false;
      return;
    }

    try {
      const result = await this.spotifyService.createPlaylist(this.spotifyNewPlaylistName.trim()).toPromise();
      if (result && result.id) {
        this.spotifyCreatePlaylistSuccess = true;
        this.spotifyCreatedPlaylistId = result.id;
        this.spotifyNewPlaylistName = '';
      } else {
        this.spotifyCreatePlaylistError = 'Failed to create Spotify playlist. Please try again.';
      }
    } catch (error) {
      console.error('Error creating Spotify playlist:', error);
      this.spotifyCreatePlaylistError = 'Failed to create Spotify playlist. Please try again.';
    } finally {
      this.isCreatingSpotifyPlaylist = false;
    }
  }

  /**
   * Adds a track to a specific Spotify playlist.
   */
  async addTrackToSpotifyPlaylist(): Promise<void> {
    this.isAddingSpotifyTrack = true;
    this.spotifyAddTrackSuccess = false;
    this.spotifyAddTrackError = null;

    if (!this.spotifyAddTrackPlaylistId.trim() || !this.spotifyAddTrackId.trim()) {
      this.spotifyAddTrackError = 'Please provide both Playlist ID and Track ID.';
      this.isAddingSpotifyTrack = false;
      return;
    }

    try {
      await this.spotifyService.addTrackToPlaylist(this.spotifyAddTrackPlaylistId.trim(), this.spotifyAddTrackId.trim()).toPromise();
      this.spotifyAddTrackSuccess = true;
      this.spotifyAddTrackPlaylistId = '';
      this.spotifyAddTrackId = '';
    } catch (error) {
      console.error('Error adding track to Spotify playlist:', error);
      this.spotifyAddTrackError = 'Failed to add track to Spotify playlist. Please try again.';
    } finally {
      this.isAddingSpotifyTrack = false;
    }
  }

  /**
   * Fetches a Spotify track based on the provided track ID.
   */
  fetchSpotifyTrack(): void {
    this.isLoadingSpotifyTrack = true;
    this.spotifyTrackData = null;
    this.spotifyTrackError = null;

    if (!this.spotifyTrackId.trim()) {
      this.spotifyTrackError = 'Please provide a valid Track ID.';
      this.isLoadingSpotifyTrack = false;
      return;
    }

    this.spotifyService.getTrack(this.spotifyTrackId.trim()).subscribe(
      (track: any) => {
        this.spotifyTrackData = track;
        this.isLoadingSpotifyTrack = false;
      },
      (error) => {
        console.error('Error fetching Spotify track:', error);
        this.spotifyTrackError = 'Failed to fetch track details. Please check the Track ID and try again.';
        this.isLoadingSpotifyTrack = false;
      }
    );
  }
}
