import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LibraryPlaylistTracksResponse } from '../interfaces/apple-music-library/apple-music-library-playlist-tracks.interface';
import { AppleMusicService } from './apple-music.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface CreatePlaylistResponse {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppleMusicLibraryService {
  constructor(
    private http: HttpClient,
    private appleMusicService: AppleMusicService
  ) {}

  /**
   * Creates a new playlist in the user's Apple Music library.
   * @param name The name of the playlist to create.
   * @returns A promise that resolves with an object indicating whether the playlist creation was successful and the playlist ID.
   *
   * https://developer.apple.com/documentation/applemusicapi/create_a_new_library_playlist
   */
  async createPlaylist(name: string): Promise<{ success: boolean; playlistId?: string }> {
    const apiURL = this.appleMusicService.getApiUrl();
    const userToken = localStorage.getItem('musicUserToken');

    try {
      const response = await this.http.post<CreatePlaylistResponse>(`${apiURL}/playlist/create`, { name }, {
        headers: {
          'Content-Type': 'application/json',
          'Music-User-Token': userToken || ''
        }
      }).toPromise();
      if (response && response.id) {
        return { success: true, playlistId: response.id };
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      console.error('Error creating playlist:', error);
      return { success: false };
    }
  }

  /**
   * Adds a specific track to an Apple Music playlist by the track's ID and the playlist's ID.
   * @param playlistId The ID of the playlist to which the track will be added.
   * @param trackId The ID of the track to add to the playlist.
   * @returns A promise that resolves to true if the track was successfully added, or false if an error occurred.
   *
   * https://developer.apple.com/documentation/applemusicapi/add_tracks_to_a_library_playlist
   */
  async addTrackToPlaylist(playlistId: string, trackId: string): Promise<boolean> {
    const apiURL = this.appleMusicService.getApiUrl();
    const userToken = localStorage.getItem('musicUserToken');
    try {
      const response = await this.http.post(`${apiURL}/playlist/${playlistId}/add-track`, { trackId }, {
        headers: {
          'Content-Type': 'application/json',
          'Music-User-Token': userToken || ''
        },
        observe: 'response'
      }).toPromise();
      return response?.status === 201;
    } catch (error) {
      console.error('Error adding track to playlist:', error);
      return false;
    }
  }

  /**
   * Retrieves details of a specific playlist from the user's Apple Music library.
   * @param playlistId The ID of the playlist to retrieve.
   * @returns An Observable containing the playlist details.
   *
   * https://developer.apple.com/documentation/applemusicapi/get_a_library_playlist
   */
  getLibraryPlaylistDetails(playlistId: string): Observable<any> {
    const appleMusic = this.appleMusicService.getMusicKitInstance();
    const apiURL = this.appleMusicService.getApiUrl();
    const url = `https://api.music.apple.com/v1/me/library/playlists/${playlistId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${appleMusic.developerToken}`,
      'Music-User-Token': localStorage.getItem('musicUserToken') || ''
    });

    return this.http.get(url, { headers });
  }

  /**
   * Fetches tracks from a specific playlist in the user's Apple Music library.
   * @param playlistId The ID of the playlist to fetch tracks from.
   * @param limit The number of tracks to fetch at a time.
   * @param offset The offset from which to start fetching tracks.
   * @returns An Observable containing the playlist tracks response.
   *
   * https://developer.apple.com/documentation/applemusicapi/get_a_library_playlist
   */
  getLibraryPlaylistTracks(
    playlistId: string,
    limit: number,
    offset: number
  ): Observable<LibraryPlaylistTracksResponse> {
    const apiURL = this.appleMusicService.getApiUrl();
    const url = `https://api.music.apple.com/v1/me/library/playlists/${playlistId}/tracks?limit=${limit}&offset=${offset}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.appleMusicService.getMusicKitInstance().developerToken}`,
      'Music-User-Token': localStorage.getItem('musicUserToken') || ''
    });

    return this.http.get<LibraryPlaylistTracksResponse>(url, { headers });
  }

  /**
   * Gets the playlists from the user's Apple Music library.
   * @returns An Observable containing the list of playlists in the user's Apple Music library.
   *
   * https://developer.apple.com/documentation/applemusicapi/get_all_library_playlists
   */
  getUserPlaylists(): Observable<any> {
    const appleMusic = this.appleMusicService.getMusicKitInstance();
    const apiURL = this.appleMusicService.getApiUrl();
    const userToken = localStorage.getItem('musicUserToken');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${appleMusic.developerToken}`,
      'Music-User-Token': userToken || ''
    });

    const url = `https://api.music.apple.com/v1/me/library/playlists`;

    return this.http.get(url, { headers }).pipe(
      tap(response => {
        //console.log('User playlists response:', response);
      })
    );
  }
}
