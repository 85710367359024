<div class="landing-container">
  <header class="header">
    <h1>Spotle - Login</h1>
    <p>Please login with your Apple Music account to continue</p>
  </header>

  <div class="features">
    <div class="feature">
      <button (click)="login()" class="btn">Login with Apple Music</button>
    </div>
  </div>

  <footer class="footer">
    <p>Developed by <a href="https://github.com/primetime43" target="_blank">primetime43</a></p>
    <p>Transfer your Spotify playlists to Apple Music easily with Spotle</p>
  </footer>
</div>
