import { Routes } from '@angular/router';
import { AppleLoginComponent } from './apple-login/apple-login.component';
import { SpotifyToAppleComponent } from './spotify-to-apple/spotify-to-apple.component';
import { SpotifyPlaylistComponent } from './spotify-playlist/spotify-playlist.component';
import { AppleToSpotifyComponent } from './apple-to-spotify/apple-to-spotify.component';
import { ImportFavoritesComponent } from './import-favorites/import-favorites.component';
import { PlaylistComparisonComponent } from './playlist-comparison/playlist-comparison.component';
import { AuthGuard } from './auth.guard';
import { ApiEndpointTestingComponent } from './api-endpoint-testing/api-endpoint-testing.component';

import { SpotifyLoginComponent } from './spotify-login/spotify-login.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeLandingComponent } from './home-landing/home-landing.component';
import { SpotifyUserInfoComponent } from './spotify-user-info/spotify-user-info.component';

export const appRoutes: Routes = [
  { path: 'apple-login', component: AppleLoginComponent },
  { path: 'api-testing', component: ApiEndpointTestingComponent },
  { path: 'spotify-to-apple', component: SpotifyToAppleComponent, canActivate: [AuthGuard] },
  { path: 'spotify-playlist', component: SpotifyPlaylistComponent },
  { path: 'apple-to-spotify', component: AppleToSpotifyComponent, canActivate: [AuthGuard] },
  { path: 'playlist-compare', component: PlaylistComparisonComponent },
  { path: 'import-favorites', component: ImportFavoritesComponent },
  { path: 'spotify-user-info', component: SpotifyUserInfoComponent },
  { path: 'spotify-login', component: SpotifyLoginComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'home', component: HomeLandingComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];
