import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppleMusicService } from './apple-music.service';
import { UtilsService } from '../shared/utils.service';
import { TrackService } from './confirm-track.service';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AppleMusicCatalogSong, AppleMusicCatalogSongResponse } from '../interfaces/apple-music-catalog/apple-music-catalog-song-response.interface';

@Injectable({
  providedIn: 'root'
})
export class AppleMusicCatalogService {
  constructor(
    private http: HttpClient,
    private appleMusicService: AppleMusicService,
    private utilsService: UtilsService,
    private trackService: TrackService
  ) { }

  // Possibly delete
  /**
   * Searches for a track on Apple Music by track name, artist name, and album name.
   * @param trackName The name of the track to search for.
   * @param artistName The name of the artist who performed the track.
   * @param albumName The name of the album the track belongs to.
   * @param isExplicit Whether the track is explicit.
   * @param trackNumber The track number in the album.
   * @param askForTrackConfirmation Whether to prompt the user for manual confirmation if no exact match is found.
   * @returns A promise containing the matched track and its confidence score, or null if no match is found.
   */
  async searchTrack(
    trackName: string,
    artistName: string,
    albumName: string,
    isExplicit: boolean,
    trackNumber: number,
    askForTrackConfirmation: boolean
  ): Promise<{ track: any, confidence: number } | null> {
    const appleMusic = this.appleMusicService.getMusicKitInstance();
    const apiURL = this.appleMusicService.getApiUrl();
    const userToken = localStorage.getItem('musicUserToken');

    try {
      const normalizedTrackName = this.utilsService.normalizeString(trackName);
      const normalizedAlbumName = this.utilsService.normalizeString(albumName);
      const normalizedArtistName = this.utilsService.normalizeString(artistName);

      console.log('Normalized track before search:', normalizedTrackName);
      console.log('Normalized artist before search:', normalizedArtistName);

      const searchTerm = `"${normalizedTrackName}" AND "${normalizedArtistName}"`;
      console.log('Search term:', searchTerm);
      const response = await this.http.get(`${apiURL}/apple-music/search`, {
        headers: {
          Authorization: `Bearer ${appleMusic.developerToken}`,
          'Music-User-Token': userToken || '',
        },
        params: new HttpParams()
          .set('term', searchTerm)
          .set('limit', '10')
          .set('types', 'songs'),
      }).toPromise();

      console.log('Search response:', response);

      const results = (response as { results: { songs: { data: any[] } } })?.results?.songs?.data;
      if (!results || results.length === 0) {
        throw new Error('No tracks found');
      }

      let confidence = 1.0; // Start with full confidence

      // Primary filter: Use "contains" check for track name and album name, and exact match for other attributes
      let filteredResults = results.filter((track) => {
        const trackNameContains = this.utilsService.normalizeString(track.attributes.name).includes(normalizedTrackName);
        const albumNameContains = this.utilsService.normalizeString(track.attributes.albumName).includes(normalizedAlbumName);
        const trackNumberMatch = track.attributes.trackNumber === trackNumber;
        const explicitMatch = (track.attributes.contentRating || 'clean') === (isExplicit ? 'explicit' : 'clean');
        const artistMatch = this.utilsService.normalizeString(track.attributes.artistName).includes(normalizedArtistName);
        console.log('track:', normalizedTrackName + ' confidence:', confidence);
        return trackNameContains && albumNameContains && trackNumberMatch && explicitMatch && artistMatch;
      });

      // If a match is found, return the result with full confidence
      if (filteredResults.length > 0) {
        console.log('track:', normalizedTrackName + ' confidence:', confidence);
        return { track: filteredResults[0], confidence };
      }

      // Fallback 1: Match on album name contains the provided string, match on track number and explicit content
      confidence -= 0.2; // Reduce confidence for the first fallback
      filteredResults = results.filter((track) => {
        const albumContains = this.utilsService.normalizeString(track.attributes.albumName).includes(normalizedAlbumName);
        const trackNumberMatch = track.attributes.trackNumber === trackNumber;
        const explicitMatch = (track.attributes.contentRating || 'clean') === (isExplicit ? 'explicit' : 'clean');
        console.log('track:', normalizedTrackName + ' confidence:', confidence);
        return albumContains && trackNumberMatch && explicitMatch;
      });

      // If a match is found in the first fallback, return the result
      if (filteredResults.length > 0) {
        console.log('track:', normalizedTrackName + ' confidence:', confidence);
        return { track: filteredResults[0], confidence };
      }

      // Fallback 2: Match on track name contains the search term and explicit content
      confidence -= 0.3; // Further reduce confidence for the second fallback
      filteredResults = results.filter((track) => {
        const trackNameContains = this.utilsService.normalizeString(track.attributes.name).includes(normalizedTrackName);
        const explicitMatch = (track.attributes.contentRating || 'clean') === (isExplicit ? 'explicit' : 'clean');
        console.log('track:', normalizedTrackName + ' confidence:', confidence);
        return trackNameContains && explicitMatch;
      });

      // If a match is found in the second fallback, return the result
      if (filteredResults.length > 0) {
        console.log('track:', normalizedTrackName + ' confidence:', confidence);
        return { track: filteredResults[0], confidence };
      }

      // If it gets here, search again and return the first result and ask the user if this is a match
      console.warn(`No match found for track: ${trackName} by ${artistName} on album ${albumName}` + ' asking user ' + askForTrackConfirmation);

      if (askForTrackConfirmation) {
        // Performing a simpler search without normalization to get the first result
        const fallbackResponse = await this.http.get(`${apiURL}/apple-music/search`, {
          headers: {
            Authorization: `Bearer ${appleMusic.developerToken}`,
            'Music-User-Token': userToken || '',
          },
          params: new HttpParams()
            .set('term', `"${trackName} ${artistName}"`)
            .set('limit', '1')
            .set('types', 'songs'),
        }).toPromise();

        const fallbackResults = (fallbackResponse as { results: { songs: { data: any[] } } })?.results?.songs?.data;
        if (fallbackResults && fallbackResults.length > 0) {
          // Return the first result and ask the user if this is a match
          const firstResult = fallbackResults[0];
          console.log('Fallback first result:', firstResult);

          const userConfirmed = await this.trackService.confirmWithUser(
            firstResult.attributes.name,
            firstResult.attributes.artistName,
            firstResult.attributes.albumName,
            trackName, // Pass original track name searched
            artistName, // Pass original artist name searched
            albumName  // Pass original album name searched
          );
          if (userConfirmed) {
            return { track: firstResult, confidence: 0.5 }; // Assuming a lower confidence since it's a fallback
          } else {
            return null;
          }
        }
      }

      return null;

    } catch (error) {
      console.log('Error searching track:', trackName);
      console.error('Error searching track:', error);
      throw error;
    }
  }

  /**
  * Retrieves details of a specific song from the Apple Music catalog.
  * @param songId The unique identifier of the song.
  * @returns An Observable containing the song details.
  *
  * Reference: https://developer.apple.com/documentation/applemusicapi/get_a_catalog_song
  */
  getCatalogSong(songId: string): Observable<AppleMusicCatalogSong> {
    const apiURL = this.appleMusicService.getApiUrl();
    if (!songId) {
      return throwError('Song ID is required.');
    }

    const url = `${apiURL}/apple-music/songs/${songId}`; // Backend endpoint
    const userToken = localStorage.getItem('musicUserToken'); // Retrieve token from localStorage

    if (!userToken) {
      return throwError('Music User Token is missing.');
    }

    const headers = new HttpHeaders({
      'Music-User-Token': userToken
    });

    return this.http.get<AppleMusicCatalogSongResponse>(url, { headers }).pipe(
      map(response => {
        if (response.data && response.data.length > 0) {
          return response.data[0];
        } else {
          throw new Error('No song data found');
        }
      }),
      catchError(error => {
        console.error('Error fetching catalog song:', error);
        const errorMessage = error.error?.error?.message || 'Failed to fetch song details. Please try again.';
        return throwError(errorMessage);
      })
    );
  }

  /**
   * Retrieves details of a specific playlist from the Apple Music catalog.
   * @param playlistId The ID of the playlist to retrieve.
   * @param storefront The storefront (region) to use, default is 'us'.
   * @returns An Observable containing the playlist details.
   *
   * https://developer.apple.com/documentation/applemusicapi/get_a_catalog_playlist
   */
  // src/app/services/apple-music-catalog.service.ts

  getCatalogPlaylistDetails(playlistId: string, storefront: string = 'us'): Observable<any> {
    const apiURL = this.appleMusicService.getApiUrl();
    if (!playlistId) {
      return throwError('Playlist ID is required.');
    }

    const url = `${apiURL}/apple-music/playlist/${playlistId}`;
    const userToken = localStorage.getItem('musicUserToken');

    if (!userToken) {
      return throwError('Music User Token is missing.');
    }

    const headers = new HttpHeaders({
      'Music-User-Token': userToken
    });

    // backend doesn't use this yet
    const params = new HttpParams().set('storefront', storefront);

    return this.http.get<any>(url, { headers, params }).pipe(
      catchError(error => {
        console.error('Error fetching playlist details:', error);
        const errorMessage = error.error?.error?.message || 'Failed to fetch playlist details. Please try again.';
        return throwError(errorMessage);
      })
    );
  }

  /**
   * Fetches tracks from a specific playlist in the Apple Music catalog.
   * @param playlistId The ID of the playlist to fetch tracks from.
   * @param limit The number of tracks to fetch at a time.
   * @param offset The offset from which to start fetching tracks.
   * @param storefront The storefront (region) to use, default is 'us'.
   * @returns An Observable containing the playlist tracks.
   *
   * https://developer.apple.com/documentation/applemusicapi/get_a_catalog_playlist
   */
  getCatalogPlaylistTracks(playlistId: string, limit: number, offset: number, storefront: string = 'us'): Observable<any> {
    const appleMusic = this.appleMusicService.getMusicKitInstance();
    const apiURL = this.appleMusicService.getApiUrl();
    const url = `https://api.music.apple.com/v1/catalog/${storefront}/playlists/${playlistId}/tracks?limit=${limit}&offset=${offset}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${appleMusic.developerToken}`,
      'Music-User-Token': localStorage.getItem('musicUserToken') || ''
    });

    return this.http.get(url, { headers }).pipe(
      tap(response => {
        // Log the response for debugging
        // console.log('getCatalogPlaylistTracks response:', response);
      })
    );
  }
}
