import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplePlaylistItem } from '../models/apple-playlist-item.model';
import { SpotifyPlaylistItem } from '../models/spotify-playlist-item.model';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  private _applePlaylistItems: BehaviorSubject<ApplePlaylistItem[]> = new BehaviorSubject<ApplePlaylistItem[]>([]);
  private _spotifyPlaylistItems: BehaviorSubject<SpotifyPlaylistItem[]> = new BehaviorSubject<SpotifyPlaylistItem[]>([]);

  // Apple Music Playlist Items
  set applePlaylistItems(items: ApplePlaylistItem[]) {
    this._applePlaylistItems.next(items);
  }

  get applePlaylistItems$() {
    return this._applePlaylistItems.asObservable();
  }

  get applePlaylistItems(): ApplePlaylistItem[] {
    return this._applePlaylistItems.getValue();
  }

  // Spotify Playlist Items
  set spotifyPlaylistItems(items: SpotifyPlaylistItem[]) {
    this._spotifyPlaylistItems.next(items);
  }

  get spotifyPlaylistItems$() {
    return this._spotifyPlaylistItems.asObservable();
  }

  get spotifyPlaylistItems(): SpotifyPlaylistItem[] {
    return this._spotifyPlaylistItems.getValue();
  }
}
