import { Component, OnInit } from '@angular/core';
import { SpotifyService } from '../services/spotify.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './spotify-user-info.component.html',
  styleUrls: ['./spotify-user-info.component.scss']
})
export class SpotifyUserInfoComponent implements OnInit {
  userInfo: any;

  constructor(private spotifyService: SpotifyService) { }

  ngOnInit() {
    this.spotifyService.getUserInfo().subscribe({
      next: (data) => {
        if (data) {
          this.userInfo = data;
        }
      },
      error: (error) => {
        console.error('Error fetching user info:', error);
      }
    });
  }
}
