import { Component, OnInit } from '@angular/core';
import { SpotifyAuthService } from '../services/spotify-auth.service';

@Component({
  selector: 'app-callback',
  template: `<p>Loading...</p>`,
  styles: []
})
export class CallbackComponent implements OnInit {

  constructor(private spotifyAuthService: SpotifyAuthService) { }

  ngOnInit() {
    this.spotifyAuthService.handleCallback();
  }
}
