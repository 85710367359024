import { Component, OnInit } from '@angular/core';
import { AppleMusicCatalogService } from '../services/apple-music-catalog.service';
import { AppleMusicLibraryService } from '../services/apple-music-library.service';
import { SpotifyService } from '../services/spotify.service';
import { ApplePlaylistItem } from '../models/apple-playlist-item.model';
import { SpotifyPlaylistItem } from '../models/spotify-playlist-item.model';

@Component({
  selector: 'app-playlist-comparison',
  templateUrl: './playlist-comparison.component.html',
  styleUrls: ['./playlist-comparison.component.scss'],
})
export class PlaylistComparisonComponent implements OnInit {
  playlistUrlA: string = '';
  playlistUrlB: string = '';
  isComparing: boolean = false;

  playlistA: any[] = [];
  playlistB: any[] = [];
  filteredItems: any[] = [];
  filter: 'all' | 'matched' | 'missing' = 'all';

  playlistAType: 'apple' | 'spotify' | 'unknown' = 'unknown';
  playlistBType: 'apple' | 'spotify' | 'unknown' = 'unknown';
  playlistAName: string = '';
  playlistBName: string = '';
  playlistATotal: number = 0;
  playlistBTotal: number = 0;
  missingCount: number = 0;
  matchedCount: number = 0;
  searchTerm: any;
  allButtonLabel: string = 'All';
  missingButtonLabel: string = 'Missing';
  matchedButtonLabel: string = 'Matched';

  constructor(
    private appleMusicCatalogService: AppleMusicCatalogService,
    private appleMusicLibraryService: AppleMusicLibraryService,
    private spotifyService: SpotifyService
  ) { }

  ngOnInit() { }

  async fetchPlaylist(url: string): Promise<any[]> {
    const playlistType = this.getPlaylistType(url);
    let allItems: any[] = [];
    let totalTracks = 0;

    if (playlistType === 'apple') {
      // Apple Music handling code remains the same
      const playlistId = this.getApplePlaylistId(url);
      if (playlistId) {
        let offset = 0;
        const limit = 100;
        let result: any;
        let metaResult: any;
        let fetchedItems: any[] = [];

        if (playlistId.startsWith('pl.')) {
          // Handle catalog Apple Music playlist
          metaResult = await this.appleMusicCatalogService
            .getCatalogPlaylistDetails(playlistId, 'us')
            .toPromise();
          if (metaResult && metaResult.data) {
            this.setPlaylistDetails(
              metaResult.data[0]?.attributes?.name + ' (Apple)',
              0
            ); // Initialize with 0 tracks
          }

          do {
            result = await this.appleMusicCatalogService
              .getCatalogPlaylistTracks(playlistId, limit, offset, 'us')
              .toPromise();
            if (result && result.data) {
              fetchedItems = result.data.map(
                (item: any) => new ApplePlaylistItem(item.attributes)
              );
              allItems = allItems.concat(fetchedItems);
              offset += fetchedItems.length;
              totalTracks += fetchedItems.length; // Accumulate the total track count
            }
          } while (fetchedItems.length === limit); // Continue fetching if there are more items
        } else if (playlistId.startsWith('p.')) {
          // Handle library Apple Music playlist
          metaResult = await this.appleMusicLibraryService
            .getLibraryPlaylistDetails(playlistId)
            .toPromise();
          if (metaResult && metaResult.data) {
            this.setPlaylistDetails(
              metaResult.data[0]?.attributes?.name + ' (Apple)',
              0
            ); // Initialize with 0 tracks
          }

          do {
            result = await this.appleMusicLibraryService
              .getLibraryPlaylistTracks(playlistId, limit, offset)
              .toPromise();
            if (result && result.data) {
              fetchedItems = result.data.map(
                (item: any) => new ApplePlaylistItem(item.attributes)
              );
              allItems = allItems.concat(fetchedItems);
              offset += fetchedItems.length;
              totalTracks += fetchedItems.length; // Accumulate the total track count
            }
          } while (fetchedItems.length === limit); // Continue fetching if there are more items
        } else {
          throw new Error('Unsupported Apple Music playlist ID format');
        }
      }
    } else if (playlistType === 'spotify') {
      const playlistId = this.getSpotifyPlaylistId(url);
      if (playlistId) {
        let offset = 0;
        const limit = 100;
        let result: any;
        let fetchedItems: SpotifyPlaylistItem[] = [];

        result = await this.spotifyService
          .getPlaylistDetails(playlistId)
          .toPromise();
        if (result) {
          this.setPlaylistDetails(
            result.name + ' (Spotify)' || 'Spotify Playlist',
            result.tracks.total || 0
          );
          totalTracks = result.tracks.total || 0; // Total tracks from Spotify metadata
        }

        do {
          result = await this.spotifyService
            .getPlaylistTracks(playlistId, limit, offset)
            .toPromise();
          if (result && result.items) {
            fetchedItems = result.items.map(
              (item: any) => new SpotifyPlaylistItem(item)
            );
            allItems = allItems.concat(fetchedItems);
            offset += fetchedItems.length;
          }
        } while (fetchedItems.length === limit); // Continue fetching if there are more items
      }
    }

    this.updateTotalTracks(totalTracks); // Update the total track count after fetching is complete
    return allItems;
  }

  setPlaylistDetails(name: string, initialTotalTracks: number) {
    if (!this.playlistAName) {
      this.playlistAName = name;
      this.playlistATotal = initialTotalTracks;
    } else if (!this.playlistBName) {
      this.playlistBName = name;
      this.playlistBTotal = initialTotalTracks;
    }
  }

  updateTotalTracks(totalTracks: number) {
    if (this.playlistAName && this.playlistATotal === 0) {
      this.playlistATotal = totalTracks;
    } else if (this.playlistBName && this.playlistBTotal === 0) {
      this.playlistBTotal = totalTracks;
    }
  }

  getPlaylistType(url: string): 'apple' | 'spotify' | 'unknown' {
    if (url.includes('music.apple.com')) {
      return 'apple';
    } else if (url.includes('open.spotify.com')) {
      return 'spotify';
    }
    return 'unknown';
  }

  getApplePlaylistId(url: string): string | null {
    const match = url.match(/(p\.[a-zA-Z0-9]+)|(pl\.[a-zA-Z0-9]+)/);
    return match ? match[0] : null;
  }

  getSpotifyPlaylistId(url: string): string | null {
    const match = url.match(/playlist\/([a-zA-Z0-9]+)/);
    return match ? match[1] : null;
  }

  async comparePlaylists() {
    this.isComparing = true;  // Set to true when the comparison starts

    try {
      // Determine the playlist types
      this.playlistAType = this.getPlaylistType(this.playlistUrlA);
      this.playlistBType = this.getPlaylistType(this.playlistUrlB);

      // Fetch both playlists
      this.playlistA = await this.fetchPlaylist(this.playlistUrlA);
      this.playlistB = await this.fetchPlaylist(this.playlistUrlB);

      this.playlistA = this.playlistA.map((itemA) => {
        const matchedItem = this.playlistB.find((itemB) => {
          return this.compareTracks(itemA, itemB);
        });

        return {
          ...itemA,
          matched: !!matchedItem,
          spotifyMatch: matchedItem,
        };
      });

      this.filterItems(); // Initial filter
      this.updateButtonLabels(); // Update button labels after comparison
    } finally {
      this.isComparing = false;  // Set to false when the comparison is done
    }
  }

  compareTracks(trackA: any, trackB: any): boolean {
    const trackNameA = this.normalizeString(trackA.trackName);
    const trackNameB = this.normalizeString(trackB.trackName);

    const artistAList = this.normalizeString(trackA.artists).split(',').map(a => a.trim());
    const artistBList = this.normalizeString(trackB.artists).split(',').map(a => a.trim());

    const albumA = this.normalizeString(trackA.albumName);
    const albumB = this.normalizeString(trackB.albumName);

    // First match: strict match for track name, artist name, album name, track number, and explicit content
    let trackNameMatch = trackNameA === trackNameB;
    let albumMatch = albumA === albumB;
    let artistMatch = this.checkArtistMatch(artistAList, artistBList);
    let durationMatch = Math.abs(this.convertToSeconds(trackA.duration) - this.convertToSeconds(trackB.duration)) <= 5;
    let trackNumberMatch = trackA.trackNumber === trackB.trackNumber;
    let explicitMatch = (trackA.explicit || false) === (trackB.explicit || false);

    if (trackNameMatch && artistMatch && albumMatch && durationMatch && trackNumberMatch && explicitMatch) {
        return true; // Strict match found
    }

    // Fallback 1: Relax the album name match to a "contains" check, match on track number and explicit content
    albumMatch = albumA.includes(albumB) || albumB.includes(albumA);
    if (trackNameMatch && artistMatch && albumMatch && durationMatch && trackNumberMatch && explicitMatch) {
        return true; // Fallback match found
    }

    // Fallback 2: Relax track name to a "contains" check, match on explicit content
    trackNameMatch = trackNameA.includes(trackNameB) || trackNameB.includes(trackNameA);
    if (trackNameMatch && explicitMatch) {
        return true; // Fallback match on track name contains and explicit
    }

    // If none of the conditions match, return false
    return false;
}

  checkArtistMatch(artistAList: string[], artistBList: string[]): boolean {
    // Check if there is at least one common artist in both lists
    return artistAList.some(artistA => artistBList.includes(artistA));
  }

  convertToSeconds(duration: string | number): number {
    if (typeof duration === 'number') {
      return duration;
    }

    const parts = duration.split(':');
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);
    return minutes * 60 + seconds;
  }

  fuzzyMatch(str1: string, str2: string, threshold: number): boolean {
    const len1 = str1.length;
    const len2 = str2.length;
    const lengthDiff = Math.abs(len1 - len2);

    return lengthDiff / Math.max(len1, len2) <= 1 - threshold;
  }

  normalizeString(str: string): string {
    return str
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[\u2018\u2019\u201A\u201B]/g, "'")
      .replace(/[\u201C\u201D\u201E\u201F]/g, '"')
      .replace(/\s*\([^)]*\)/g, '')
      .replace(/\s*\[[^\]]*\]/g, '')
      .replace(/\s*\{[^}]*\}/g, '')
      .replace(/\s*\-\s*single$/i, '')
      .replace(/feat\.|ft\./gi, '');
  }

  updateButtonLabels() {
    this.matchedCount = this.playlistA.filter(item => item.matched).length;
    this.missingCount = this.playlistA.length - this.matchedCount;

    this.matchedButtonLabel = `Matched (${this.matchedCount})`;
    this.missingButtonLabel = `Missing (${this.missingCount})`;
    this.allButtonLabel = `All (${this.playlistA.length})`;
  }

  filterItems() {
    let items = this.playlistA;

    // Apply filter based on the selected type (all, matched, missing)
    if (this.filter === 'matched') {
      items = items.filter((item) => item.matched);
    } else if (this.filter === 'missing') {
      items = items.filter((item) => !item.matched);
    }

    // Apply search filtering
    if (this.searchTerm) {
      const searchTermNormalized = this.normalizeString(this.searchTerm);
      items = items.filter(
        (item) =>
          this.normalizeString(item.trackName).includes(searchTermNormalized) ||
          this.normalizeString(item.artists).includes(searchTermNormalized) ||
          this.normalizeString(item.albumName).includes(searchTermNormalized)
      );
    }

    this.filteredItems = items;
  }
}
