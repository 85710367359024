// src/app/app.module.ts

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, MenuController } from '@ionic/angular';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import { AppComponent } from './app.component';
import { ApiEndpointTestingComponent } from './api-endpoint-testing/api-endpoint-testing.component';
import { AppleLoginComponent } from './apple-login/apple-login.component';
import { SpotifyToAppleComponent } from './spotify-to-apple/spotify-to-apple.component';
import { AppleToSpotifyComponent } from './apple-to-spotify/apple-to-spotify.component';
import { SpotifyPlaylistComponent } from './spotify-playlist/spotify-playlist.component';
import { ImportFavoritesComponent } from './import-favorites/import-favorites.component';
import { SpotifyLoginComponent } from './spotify-login/spotify-login.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeLandingComponent } from './home-landing/home-landing.component';
import { SpotifyUserInfoComponent } from './spotify-user-info/spotify-user-info.component';
import { PlaylistComparisonComponent } from './playlist-comparison/playlist-comparison.component';
import { LogViewerComponent } from './log-viewer/log-viewer.component';

import { appRoutes } from './app.routes';
import { AppleMusicService } from './services/apple-music.service';
import { SpotifyService } from './services/spotify.service';
import { PlaylistService } from './services/playlist.service';
import { ToastMessagesService } from './services/toast-messages.service';
import { AuthGuard } from './auth.guard';
import { SpotifyAuthService } from './services/spotify-auth.service';
import { AuthStatusService } from './services/auth-status.service';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    AppleLoginComponent,
    ApiEndpointTestingComponent,
    SpotifyPlaylistComponent,
    SpotifyLoginComponent,
    CallbackComponent,
    SpotifyUserInfoComponent,
    AppleToSpotifyComponent,
    SpotifyToAppleComponent,
    HomeLandingComponent,
    PlaylistComparisonComponent,
    LogViewerComponent,
    ImportFavoritesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    IonicModule.forRoot(),
    BrowserAnimationsModule,
    HighlightModule
  ],
  providers: [
    AppleMusicService,
    SpotifyService,
    PlaylistService,
    ToastMessagesService,
    AuthGuard,
    SpotifyAuthService,
    AuthStatusService,
    MenuController,
    DatePipe,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
