export class SpotifyPlaylistItem {
  trackName: string;
  artists: string;
  albumName: string;
  duration: string;
  explicit: boolean;
  addedAt: Date;
  spotifyUrl: string;
  albumImageUrl: string;
  trackNumber: number;
  ISRC: string;

  constructor(data: any) {
    this.trackName = data.track.name || 'Unknown';
    this.artists = data.track.artists.map((artist: any) => artist.name).join(', ') || 'Unknown';
    this.albumName = data.track.album.name || 'Unknown';
    this.duration = this.getDuration(data.track.duration_ms);
    this.explicit = data.track.explicit;
    this.addedAt = new Date(data.added_at);
    this.spotifyUrl = data.track.external_urls.spotify;
    this.albumImageUrl = data.track.album.images[0]?.url || '';
    this.trackNumber = data.track.track_number;
    this.ISRC = data.track.external_ids.isrc || '';
  }

  private getDuration(durationMs: number): string {
    const minutes = Math.floor(durationMs / 60000);
    const seconds = ((durationMs % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.length < 2 ? '0' : ''}${seconds}`;
  }
}
