import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { LogEntry } from '../interfaces/log-entry.interface';  // Adjust the path accordingly

@Injectable({
  providedIn: 'root'
})
export class LogExportService {
  constructor(private datePipe: DatePipe) {}

  exportLogToExcel(logEntries: LogEntry[], playlistName: string) {
    // Prepare the data for each sheet
    const allLogs = logEntries.map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: entry.success ? 'Success' : 'Error',
      Confidence: this.getConfidenceLabel(entry.confidence)
    }));

    const successLogs = logEntries.filter(entry => entry.success).map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: 'Success',
      Confidence: this.getConfidenceLabel(entry.confidence)
    }));

    const errorLogs = logEntries.filter(entry => !entry.success).map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: 'Error',
      Confidence: this.getConfidenceLabel(entry.confidence)
    }));

    const highConfidenceLogs = logEntries.filter(entry => entry.confidence >= 0.8).map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: entry.success ? 'Success' : 'Error',
      Confidence: 'High'
    }));

    const mediumConfidenceLogs = logEntries.filter(entry => entry.confidence >= 0.5 && entry.confidence < 0.8).map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: entry.success ? 'Success' : 'Error',
      Confidence: 'Medium'
    }));

    const lowConfidenceLogs = logEntries.filter(entry => entry.confidence > 0 && entry.confidence < 0.5).map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: entry.success ? 'Success' : 'Error',
      Confidence: 'Low'
    }));

    const noConfidenceLogs = logEntries.filter(entry => entry.confidence === 0).map(entry => ({
      Timestamp: this.datePipe.transform(entry.timestamp, 'medium'),
      SongName: entry.songName,  // Include the song name
      ArtistName: entry.artist,
      AlbumName: entry.album,
      Message: entry.message,
      Success: entry.success ? 'Success' : 'Error',
      Confidence: 'No Confidence'
    }));

    // Create a workbook with multiple sheets
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Add each sheet to the workbook
    const wsAll: XLSX.WorkSheet = XLSX.utils.json_to_sheet(allLogs);
    XLSX.utils.book_append_sheet(wb, wsAll, 'All Logs');

    const wsSuccess: XLSX.WorkSheet = XLSX.utils.json_to_sheet(successLogs);
    XLSX.utils.book_append_sheet(wb, wsSuccess, 'Success Logs');

    const wsError: XLSX.WorkSheet = XLSX.utils.json_to_sheet(errorLogs);
    XLSX.utils.book_append_sheet(wb, wsError, 'Error Logs');

    const wsHighConfidence: XLSX.WorkSheet = XLSX.utils.json_to_sheet(highConfidenceLogs);
    XLSX.utils.book_append_sheet(wb, wsHighConfidence, 'High Confidence Logs');

    const wsMediumConfidence: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mediumConfidenceLogs);
    XLSX.utils.book_append_sheet(wb, wsMediumConfidence, 'Medium Confidence Logs');

    const wsLowConfidence: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lowConfidenceLogs);
    XLSX.utils.book_append_sheet(wb, wsLowConfidence, 'Low Confidence Logs');

    const wsNoConfidence: XLSX.WorkSheet = XLSX.utils.json_to_sheet(noConfidenceLogs);
    XLSX.utils.book_append_sheet(wb, wsNoConfidence, 'No Confidence Logs');

    // Generate Excel file and trigger download
    const currentDate = this.datePipe.transform(new Date(), 'yyyyMMdd');
    XLSX.writeFile(wb, `${playlistName}_log_${currentDate}.xlsx`);
  }

  private getConfidenceLabel(confidence: number): string {
    if (confidence >= 0.8) {
      return 'High';
    } else if (confidence >= 0.5) {
      return 'Medium';
    } else if (confidence > 0) {
      return 'Low';
    } else {
      return 'No Confidence';
    }
  }
}
