import { LogEntry } from '../../interfaces/log-entry.interface';
import { ElementRef } from '@angular/core';

// src/app/shared/playlist.component.ts
export abstract class PlaylistComponent {
  playlistUrl: string = '';
  playlistName: string = '';
  currentItems: any[] = [];
  filteredItems: any[] = [];
  currentPage: number = 1;
  totalItems: number = 0;
  itemsPerPage: number = 100;
  logEntries: LogEntry[] = [];
  showPlaylistItems: boolean = false;
  showLog: boolean = false;
  progress: number = 0;
  searchTerm: string = '';
  processedItems: number = 0;
  isProcessing: boolean = false;
  isPlaylistFetched: boolean = false;

  abstract getPlaylistItemsObservable(): any;

  abstract detectChanges(): void;

  abstract updateCurrentItems(): void;

  resetState() {
    this.currentItems = [];
    this.filteredItems = [];
    this.currentPage = 1;
    this.totalItems = 0;
    this.logEntries = [];
    this.showPlaylistItems = false;
    this.showLog = false;
    this.progress = 0;
    this.processedItems = 0;
    this.searchTerm = '';
    this.showLog = false;
    this.isProcessing = false;
    this.isPlaylistFetched = false;
  }

  addLog(message: string, success: boolean, confidence: number, songName: string = '', artist: string = '', album: string = '') {
    this.logEntries.push({ message, success, timestamp: new Date(), confidence, songName, artist, album });
  }

  clearLog() {
    this.logEntries = [];
  }

  onFilterChanged(filter: string) {
    console.log(`Filter changed to: ${filter}`);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.updateCurrentItems();
  }

  get totalPages(): number {
    return Math.ceil(this.filteredItems.length / this.itemsPerPage);
  }

  filterItems() {
    this.currentPage = 1;
    this.updateCurrentItems();
  }
}
