import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppleMusicService } from '../services/apple-music.service';
import { SpotifyAuthService } from '../services/spotify-auth.service';
import { SpotifyService } from '../services/spotify.service';
import { ToastMessagesService } from '../services/toast-messages.service';
import { AuthStatusService } from '../services/auth-status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss']
})
export class HomeLandingComponent implements OnInit, OnDestroy {
  private spotifyLoginSuccessSubscription: Subscription;
  private appleLoginSuccessSubscription: Subscription;
  isSpotifyLoggedIn = false;
  isAppleMusicLoggedIn = false;
  userProfile: any;
  playlists: any;

  constructor(
    private appleMusicService: AppleMusicService,
    private spotifyAuthService: SpotifyAuthService,
    private spotifyService: SpotifyService,
    private authStatusService: AuthStatusService,
    private toastService: ToastMessagesService
  ) {
    this.spotifyLoginSuccessSubscription = this.spotifyAuthService.loginSuccess.subscribe(() => {
      this.isSpotifyLoggedIn = true;
      this.loadUserProfile();
      this.loadPlaylists();
    });

    this.appleLoginSuccessSubscription = this.appleMusicService.loginSuccess.subscribe(() => {
      this.isAppleMusicLoggedIn = true;
    });
  }

  ngOnInit() {
    this.isSpotifyLoggedIn = !!localStorage.getItem('spotify_token');
    this.isAppleMusicLoggedIn = !!localStorage.getItem('musicUserToken');
    console.log('isSpotifyLoggedIn:', this.isSpotifyLoggedIn);
    console.log('isAppleMusicLoggedIn:', this.isAppleMusicLoggedIn);

    if (this.isSpotifyLoggedIn) {
      //this.loadUserProfile();
      //this.loadPlaylists();
    }
  }

  async loginToAppleMusic() {
    try {
      if (this.isAppleMusicLoggedIn) {
        this.toastService.presentToast('Successfully logged into Apple Music', 'success');
      } else {
        await this.appleMusicService.login();
        this.toastService.presentToast('Successfully logged into Apple Music', 'success');
      }
    } catch (error) {
      console.error('Error logging into Apple:', error);
      this.toastService.presentToast('Error logging into Apple Music', 'danger');
    }
  }

  async loginToSpotify() {
    try {
      if (this.isSpotifyLoggedIn) {
        this.toastService.presentToast('Successfully logged into Spotify', 'success');
      } else {
        this.spotifyAuthService.login();  // No need to await, as it's only a redirect now
        // The toast and redirection will be handled in handleCallback() after successful login
      }
    } catch (error) {
      console.error('Error logging into Spotify:', error);
      this.toastService.presentToast('Error logging into Spotify', 'danger');
    }
  }

  signOutSpotify() {
    this.spotifyAuthService.signOut();
    this.isSpotifyLoggedIn = false;
    this.toastService.presentToast('Successfully signed out of Spotify', 'success');
    console.log('Signed out of Spotify');
  }

  signOutAppleMusic() {
    // Remove the token from localStorage
    localStorage.removeItem('musicUserToken');

    // Update the AuthStatusService to reflect the user is logged out
    this.authStatusService.updateMusicUserStatus(false);

    // Update the local component state
    this.isAppleMusicLoggedIn = false;

    // Show a toast message confirming the sign out
    this.toastService.presentToast('Successfully signed out of Apple Music', 'success');
    console.log('Signed out of Apple Music');
  }


  loadUserProfile() {
    this.spotifyService.loadUserProfile().subscribe(
      profile => {
        this.userProfile = profile;
      },
      error => {
        console.error('Error loading user profile:', error);
        this.toastService.presentToast('Error loading user profile', 'danger');
      }
    );
  }

  loadPlaylists() {
    this.spotifyService.loadPlaylists().subscribe(
      playlists => {
        this.playlists = playlists;
      },
      error => {
        console.error('Error loading playlists:', error);
        this.toastService.presentToast('Error loading playlists', 'danger');
      }
    );
  }

  ngOnDestroy() {
    if (this.spotifyLoginSuccessSubscription) {
      this.spotifyLoginSuccessSubscription.unsubscribe();
    }

    if (this.appleLoginSuccessSubscription) {
      this.appleLoginSuccessSubscription.unsubscribe();
    }
  }
}
