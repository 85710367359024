<div class="landing-container">
  <div class="card playlist-input-section">
    <div class="url-inputs">
      <input type="text" [(ngModel)]="playlistUrlA" placeholder="Enter Playlist URL 1" class="url-input"/>
      <input type="text" [(ngModel)]="playlistUrlB" placeholder="Enter Playlist URL 2" class="url-input"/>
      <button
        (click)="comparePlaylists()"
        class="compare-button"
        [disabled]="isComparing">
        <span *ngIf="!isComparing">Compare Playlists</span>
        <span *ngIf="isComparing">Comparing...</span>
      </button>
    </div>
    <p class="comparison-note">
      Compare tracks between the two playlists. The results will show which tracks are matched or missing.
    </p>
  </div>

  <div class="card comparison-section" *ngIf="playlistA.length && playlistB.length">
    <div class="comparison-summary">
      <h2>Comparison Summary</h2>
      <p>Total Tracks in {{ playlistAName }}: {{ playlistATotal }}</p>
      <p>Total Tracks in {{ playlistBName }}: {{ playlistBTotal }}</p>
      <p>Matched Tracks: {{ matchedCount }}</p>
      <p>Missing Tracks: {{ missingCount }}</p>
    </div>

    <div class="filter-controls">
      <button (click)="filter = 'all'; filterItems()" [class.active]="filter === 'all'">Show All</button>
      <button (click)="filter = 'matched'; filterItems()" [class.active]="filter === 'matched'">Show Matched</button>
      <button (click)="filter = 'missing'; filterItems()" [class.active]="filter === 'missing'">Show Missing</button>
    </div>

    <div class="search-container">
      <input type="text" [(ngModel)]="searchTerm" (input)="filterItems()" placeholder="Search playlist..." class="search-bar" />
    </div>

    <div class="comparison-results">
      <div *ngFor="let item of filteredItems" class="playlist-item" [class.matched]="item.matched" [class.not-matched]="!item.matched">
        <p><strong>Track:</strong> {{ item.trackName }}</p>
        <p><strong>Artist:</strong> {{ item.artists }}</p>
        <p><strong>Album:</strong> {{ item.albumName }}</p>
        <p *ngIf="!item.matched" class="not-matched">❌ Missing from {{ playlistBName }}</p>
        <p *ngIf="item.matched" class="matched">✅ Found in {{ playlistBName }}</p>
        <hr/>
      </div>
    </div>
  </div>

  <footer class="footer">
    <p>Developed by <a href="https://github.com/primetime43" target="_blank">primetime43</a></p>
  </footer>
</div>
