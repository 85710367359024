import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmTrackDialogComponent } from '../shared/confirm-track-dialog/confirm-track-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TrackService {
  constructor(private dialog: MatDialog) {}

  confirmWithUser(
    foundTrackName: string,
    foundArtistName: string,
    foundAlbumName: string,
    originalTrackName: string,  // Pass original search details
    originalArtistName: string,
    originalAlbumName: string
  ): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmTrackDialogComponent, {
      width: '400px',
      data: {
        foundTrackName,
        foundArtistName,
        foundAlbumName,
        originalTrackName,
        originalArtistName,
        originalAlbumName
      },
    });

    return dialogRef.afterClosed().toPromise();  // This will return true or false based on user action
  }
}
