import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppleMusicCatalogService } from '../services/apple-music-catalog.service';
import { AppleMusicLibraryService } from '../services/apple-music-library.service';
import { SpotifyService } from '../services/spotify.service';
import { ApplePlaylistItem } from '../models/apple-playlist-item.model';
import { ChangeDetectorRef } from '@angular/core';
import { PlaylistService } from '../services/playlist.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ToastMessagesService } from '../services/toast-messages.service';
import { PlaylistComponent } from '../shared/playlist/playlist.component';
import { TrackResult } from '../models/AS-track-result.model';

@Component({
  selector: 'app-apple-to-spotify',
  templateUrl: './apple-to-spotify.component.html',
  styleUrls: ['./apple-to-spotify.component.scss']
})
export class AppleToSpotifyComponent extends PlaylistComponent implements OnInit, OnDestroy {

  playlistItemsSubscription: Subscription | undefined;
  filter: 'all' | 'success' | 'error' = 'all';
  isFetching: boolean = false;  // Indicator for fetching playlist
  isCreating: boolean = false;  // Indicator for creating playlist
  askForTrackConfirmation: boolean = false;

  constructor(
    private appleMusicCatalogService: AppleMusicCatalogService,
    private appleMusicLibraryService: AppleMusicLibraryService,
    private spotifyService: SpotifyService,
    public playlistService: PlaylistService,
    private toastService: ToastMessagesService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.playlistItemsSubscription = this.playlistService.applePlaylistItems$.subscribe(() => {
      this.updateCurrentItems();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.playlistItemsSubscription) {
      this.playlistItemsSubscription.unsubscribe();
    }
  }

  getPlaylistItemsObservable() {
    return this.playlistService.applePlaylistItems$;
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }

  updateCurrentItems() {
    this.filteredItems = this.playlistService.applePlaylistItems.filter(item =>
      item.trackName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.artists.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.albumName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.currentItems = this.filteredItems.slice(start, end);
  }

  async createPlaylist() {
      this.isProcessing = true;
      this.isCreating = true;  // Start the creating indicator

      this.showPlaylistItems = false;
      this.showLog = true;

      try {
          if (this.playlistName) {
              const result = await firstValueFrom(this.spotifyService.createPlaylist(this.playlistName, 'Playlist created by Spotle, developed by primetime43.', false));

              if (result && result.id) {
                  await this.searchAndAddTracks(result.id);  // Wait for this function to complete
              } else {
                this.toastService.presentToast(`Failed to create playlist ${this.playlistName}!`, 'danger', 0, true);
              }
          } else {
            this.toastService.presentToast(`Playlist name is required to create a playlist.`, 'danger');
          }
      } catch (error) {
          console.error('Error creating playlist:', error);
          this.toastService.presentToast(`Failed to create playlist ${this.playlistName}!`, 'danger', 0, true);
      }
  }

  async searchAndAddTracks(playlistId: string) {
    const trackResults: TrackResult[] = await this.searchTracks();
    this.addTracksToPlaylist(playlistId, trackResults);
  }

  async searchTracks() {
    const trackResults: TrackResult[] = [];
    for (const item of this.playlistService.applePlaylistItems) {
      try {
        const trackResult = await this.spotifyService.searchTrack(
          item.trackName,
          item.artists,
          item.albumName,
          item.explicit,
          item.trackNumber,
          this.askForTrackConfirmation
        ).toPromise();

        if (trackResult && trackResult.track) {
          trackResults.push({
            id: trackResult.track.id,
            name: trackResult.track.name,
            confidence: trackResult.confidence,
            albumName: trackResult.track.album.name,
            artistName: trackResult.track.artists[0].name
          });
          //this.addLog(`Found track: ${item.trackName} by ${item.artists} with confidence ${trackResult.confidence.toFixed(2)}`, true, trackResult.confidence, item.trackName);
        } else {
          //this.addLog(`Track not found: ${item.trackName} by ${item.artists}`, false, 0, item.trackName);
        }
      } catch (error) {
        console.error('Error searching track:', error);
        this.addLog(`Error searching track: ${item.trackName} by ${item.artists}`, false, 0, item.trackName, item.artists, item.albumName);
      }
    }
    return trackResults;
  }

  async addTracksToPlaylist(playlistId: string, tracks: TrackResult[]) {
    this.processedItems = 0;
    for (const track of tracks) {
      try {
        const addResult = await firstValueFrom(this.spotifyService.addTrackToPlaylist(playlistId, track.id));

        if (addResult.snapshot_id) {
          this.addLog(`Successfully added track: ${track.name} to playlist: ${this.playlistName} (Confidence: ${track.confidence.toFixed(2)})`, true, track.confidence, track.name, track.artistName, track.albumName);
        } else {
          this.addLog(`Failed to add track: ${track.name} to playlist: ${this.playlistName}`, false, track.confidence, track.name, track.artistName, track.albumName);
        }
      } catch (error) {
        console.error('Error adding track to playlist:', error);
        this.addLog(`Error adding track: ${track.name} to playlist: ${this.playlistName}`, false, track.confidence, track.name, track.artistName, track.albumName);
      }

      this.processedItems++;
      this.progress = Math.round((this.processedItems / tracks.length) * 100); // Round to whole number
      this.cdr.detectChanges();
    }

    // Only set `isProcessing` to false after all tracks have been processed.
    this.isProcessing = false;
    this.isCreating = false;
    this.cdr.detectChanges();

    this.toastService.presentToast(`Playlist ${this.playlistName} successfully created on Spotify!`, 'success', 0, true);
  }

  getApplePlaylistId(url: string): string | null {
    const match = url.match(/(p\.[a-zA-Z0-9]+)|(pl\.[a-zA-Z0-9]+)/);
    return match ? match[0] : null;
  }

  async fetchPlaylist() {
    this.isFetching = true;
    this.resetState();

    const playlistId = this.getApplePlaylistId(this.playlistUrl);

    if (!playlistId) {
      this.toastService.presentToast('Invalid Apple Music playlist URL.', 'danger', 3000, false, 'left');
      this.isFetching = false;
      return;
    }

    this.clearLog();
    this.showLog = false;

    let allItems: ApplePlaylistItem[] = [];
    let fetchedItems: ApplePlaylistItem[] = [];
    let result: any;
    let metaResult: any;
    let offset = 0;
    const limit = 100;

    try {
      if (playlistId.startsWith('pl.')) {
        // Fetch playlist metadata to get the total track count
        metaResult = await this.appleMusicCatalogService
          .getCatalogPlaylistDetails(playlistId, 'us')
          .toPromise();

        if (metaResult && metaResult.data) {
          this.playlistName = metaResult.data[0].attributes?.name;
          const trackCount = metaResult.data[0].attributes?.trackCount || 0;

          // Fetch tracks with pagination
          do {
            result = await this.appleMusicCatalogService
              .getCatalogPlaylistTracks(playlistId, limit, offset, 'us')
              .toPromise();

            if (result && result.data) {
              fetchedItems = result.data.map(
                (item: any) => new ApplePlaylistItem(item.attributes)
              );
              allItems = allItems.concat(fetchedItems);
              offset += fetchedItems.length;
              this.totalItems += fetchedItems.length;
            }
          } while (fetchedItems.length === limit && this.totalItems < trackCount);  // Stop if fewer items are returned

          this.totalItems = allItems.length;
          this.playlistService.applePlaylistItems = allItems;
        }
      } else if (playlistId.startsWith('p.')) {
        metaResult = await this.appleMusicLibraryService
          .getLibraryPlaylistDetails(playlistId)
          .toPromise();

        if (metaResult && metaResult.data) {
          this.playlistName = metaResult.data[0].attributes?.name;
        }

        // Fetch tracks from the user's library with pagination
        do {
          result = await this.appleMusicLibraryService
            .getLibraryPlaylistTracks(playlistId, limit, offset)
            .toPromise();

          if (result && result.data) {
            fetchedItems = result.data.map(
              (item: any) => new ApplePlaylistItem(item.attributes)
            );
            allItems = allItems.concat(fetchedItems);
            offset += fetchedItems.length;
            this.totalItems += fetchedItems.length;
          }
        } while (fetchedItems.length === limit);  // Stop if fewer items are returned

        this.totalItems = allItems.length;
        this.playlistService.applePlaylistItems = allItems;
      }

      this.updateCurrentItems();
      this.isPlaylistFetched = true;
    } catch (error) {
      console.error('Error fetching playlist:', error);
      this.toastService.presentToast(
        'Failed to fetch playlist. Make sure the playlist is public or log in to Apple Music and try again.',
        'danger',
        2000
      );
    } finally {
      this.isFetching = false;
    }
  }
}
