import { Component } from '@angular/core';
import { AppleMusicCatalogService } from '../services/apple-music-catalog.service';
import { AppleMusicLibraryService } from '../services/apple-music-library.service';
import { SpotifyService } from '../services/spotify.service';
import { AuthStatusService } from '../services/auth-status.service';
import { ToastMessagesService } from '../services/toast-messages.service';
import { LogExportService } from '../services/log-export.service';
import { LogEntry } from '../interfaces/log-entry.interface';

@Component({
  selector: 'app-import-favorites',
  templateUrl: './import-favorites.component.html',
  styleUrls: ['./import-favorites.component.scss']
})
export class ImportFavoritesComponent {
  favoriteSongs: any[] = [];
  fetched: boolean = false;
  source: string = '';
  isLoading: boolean = false;  // Track loading state

  constructor(
    private appleMusicCatalogService: AppleMusicCatalogService,
    private appleMusicLibraryService: AppleMusicLibraryService,
    private spotifyService: SpotifyService,
    private authStatusService: AuthStatusService,
    private toastService: ToastMessagesService,
    private logExportService: LogExportService
  ) {}

  /**
   * Fetches the favorite songs from Apple Music.
   */
  fetchAppleMusicFavorites() {
    this.isLoading = true; // Start loading
    this.authStatusService.isMusicUserLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.source = 'Apple Music';
        this.appleMusicLibraryService.getUserPlaylists().subscribe(playlists => {
          const favoriteSongsPlaylist = this.findFavoriteSongsPlaylist(playlists.data);
          if (favoriteSongsPlaylist) {
            this.appleMusicLibraryService.getLibraryPlaylistTracks(favoriteSongsPlaylist.id, 100, 0).subscribe(tracks => {
              this.favoriteSongs = tracks.data;
              this.fetched = true;
              this.isLoading = false; // Stop loading
            });
          } else {
            this.favoriteSongs = [];
            this.fetched = true;
            this.isLoading = false; // Stop loading
          }
        });
      } else {
        this.toastService.presentToast('User is not logged in to Apple Music.', 'danger', 2000);
        this.isLoading = false; // Stop loading on failure
      }
    });
  }

  /**
   * Fetches the favorite songs from Spotify.
   */
  fetchSpotifyFavorites() {
    this.isLoading = true; // Start loading
    this.authStatusService.isSpotifyUserLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.source = 'Spotify';
        this.spotifyService.getFavoriteSongs(50, 0).subscribe(tracks => {
          this.favoriteSongs = tracks.items;
          this.fetched = true;
          this.isLoading = false; // Stop loading
        }, error => {
          console.error('Error fetching Spotify favorites:', error);
          this.fetched = true;
          this.isLoading = false; // Stop loading on error
        });
      } else {
        this.toastService.presentToast('User is not logged in to Spotify.', 'danger', 2000);
        this.isLoading = false; // Stop loading on failure
      }
    });
  }

  /**
   * Exports the favorite songs to an Excel file.
   */
  exportFavoritesToExcel() {
    const logEntries: LogEntry[] = this.favoriteSongs.map((song, index) => ({
      timestamp: new Date(),
      songName: song.attributes?.name || song.track?.name || '',
      artist: song.attributes?.artistName ||
              (song.track?.artists?.map((a: any) => a.name).join(', ') || ''),
      album: song.attributes?.albumName || song.track?.album?.name || '',
      message: `Exported from ${this.source}`,
      success: true,
      confidence: 1.0
    }));

    console.log('Log Entries for Export:', logEntries);

    this.logExportService.exportLogToExcel(logEntries, `${this.source}_Favorites`);
  }

  /**
   * Finds the "Favorite Songs" playlist from Apple Music playlists.
   */
  private findFavoriteSongsPlaylist(playlists: any[]): any | null {
    return playlists.find(playlist => playlist.attributes?.name === 'Favorite Songs') || null;
  }
}
