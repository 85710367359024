// src/app/shared/utils.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TrackService } from '../services/confirm-track.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  constructor(private http: HttpClient, private trackService: TrackService) {}

  /**
   * Normalizes and simplifies strings by removing parentheticals, features, unnecessary details,
   * keeping only the first artist listed, and taking only the part before the pipe.
   * @param str The string to normalize.
   * @returns The normalized string.
   */
  normalizeString(str: string): string {
    return str.toLowerCase()
      .split('|')[0] // Take only the part before the first pipe
      .split(',')[0] // Take only the first part if there are multiple artists listed
      .replace(/\(.*?\)/g, '') // Remove anything in parentheses
      .replace(/\[.*?feat.*?\]/gi, '') // Remove anything in square brackets containing 'feat' (case insensitive)
      .replace(/feat\.|featuring|ft\./gi, '') // Remove 'feat', 'featuring', 'ft.' in any case
      .replace(/\s+/g, ' ') // Normalize spaces
      .trim();
  }

  /**
   * Formats an image URL by replacing placeholders {w} and {h} with actual width and height values.
   * @param urlTemplate The image URL template containing {w} and {h} as placeholders.
   * @param width The desired width of the image.
   * @param height The desired height of the image.
   * @returns The formatted image URL with actual width and height values.
   */
  formatImageUrl(urlTemplate: string, width: number = 300, height: number = 300): string {
    if (!urlTemplate) {
      console.error('UtilsService: URL template is undefined or null.');
      return '';
    }

    // Replace {w} and {h} with actual values
    let formattedUrl = urlTemplate.replace('{w}', width.toString()).replace('{h}', height.toString());

    // Optionally, you can add more replacements or validations here

    return formattedUrl;
  }

}
