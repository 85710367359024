import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppleMusicService } from '../services/apple-music.service';

@Component({
  selector: 'app-apple-login',
  templateUrl: './apple-login.component.html',
  styleUrls: ['./apple-login.component.scss']
})
export class AppleLoginComponent {
  constructor(
    private appleMusicService: AppleMusicService,
    private router: Router
  ) {}

  async login() {
    try {
      await this.appleMusicService.login();
    } catch (error) {
      console.error('Error logging into Apple:', error);
    }
  }
}
