<div class="dialog-container">
  <h2>Confirm Track Match</h2>
  <p>Is this the correct track?</p>

  <div class="track-info">
    <p><strong>Original Track Name:</strong> {{ originalTrackName }}</p>
    <p><strong>Original Artist:</strong> {{ originalArtistName }}</p>
    <p><strong>Original Album:</strong> {{ originalAlbumName }}</p>
    <br>
    <p><strong>Found Track Name:</strong> {{ foundTrackName }}</p>
    <p><strong>Found Artist:</strong> {{ foundArtistName }}</p>
    <p><strong>Found Album:</strong> {{ foundAlbumName }}</p>
  </div>

  <div class="dialog-actions">
    <button class="btn btn-confirm" (click)="confirm()">Yes</button>
    <button class="btn btn-reject" (click)="reject()">No</button>
  </div>
</div>
