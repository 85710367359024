<div class="container mt-5">
  <h1 class="text-center mb-4">Convert Spotify Playlist to Apple Music</h1>

  <!-- First Container: Input and Create Playlist Section -->
  <div class="input-container">
    <!-- Input Group for Spotify Playlist URL -->
    <div class="d-flex justify-content-center mb-4">
      <input
        type="text"
        class="form-control playlist-url-input me-2"
        [(ngModel)]="playlistUrl"
        placeholder="Enter Spotify Playlist URL"
      />
      <button
        class="btn btn-success"
        (click)="fetchPlaylist()"
        [disabled]="isFetching || !playlistUrl">
        <span *ngIf="!isFetching">Fetch Playlist</span>
        <span *ngIf="isFetching">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Fetching...
        </span>
      </button>
    </div>

    <!-- Create Playlist Button -->
    <button
      class="btn btn-success w-100 mb-4"
      (click)="createPlaylist()"
      [disabled]="isCreating || currentItems.length === 0">
      <span *ngIf="!isCreating">Create Playlist</span>
      <span *ngIf="isCreating">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Creating...
      </span>
    </button>
  </div>

  <!-- Second Container: Playlist Items, Log, and Progress Section -->
  <div class="details-container">
    <!-- Toggle for Playlist Items Display -->
    <div class="form-check form-switch mb-3">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="showPlaylistItems"
        id="showPlaylistItems"
      />
      <label class="form-check-label" for="showPlaylistItems">
        Show Playlist Items ({{ currentItems.length }} items out of {{ totalItems }})
      </label>
    </div>

    <!-- Manual Confirmation Toggle -->
    <div class="form-check my-4">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="askForTrackConfirmation"
        id="manualConfirmation"
      />
      <label class="form-check-label" for="manualConfirmation">
        Ask user for manual confirmation on uncertain tracks
      </label>
    </div>

    <!-- Search Input -->
    <div *ngIf="showPlaylistItems" class="mb-3">
      <input
        type="text"
        [(ngModel)]="searchTerm"
        (input)="filterItems()"
        placeholder="Search playlist..."
        class="form-control"
      />
    </div>

    <!-- Pagination Controls -->
    <div *ngIf="totalPages > 1 && showPlaylistItems" class="d-flex justify-content-between align-items-center mt-4">
      <button
        class="btn btn-secondary"
        [disabled]="currentPage === 1"
        (click)="onPageChange(currentPage - 1)">
        Previous
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        class="btn btn-secondary"
        [disabled]="currentPage === totalPages"
        (click)="onPageChange(currentPage + 1)">
        Next
      </button>
    </div>

    <!-- Playlist Items as Cards -->
    <div *ngIf="showPlaylistItems && currentItems.length > 0 && !isFetching" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
      <div *ngFor="let item of currentItems" class="col">
        <div class="card h-100 shadow-sm">
          <img [src]="item.albumImageUrl" class="card-img-top album-image" alt="{{ item.albumName }}" />
          <div class="card-body">
            <h5 class="card-title">{{ item.trackName }}</h5>
            <p class="card-text">
              <strong>Artist:</strong> {{ item.artists }}<br />
              <strong>Album:</strong> {{ item.albumName }}<br />
              <strong>Duration:</strong> {{ item.duration }}<br />
              <strong>Explicit:</strong> {{ item.explicit ? 'Yes' : 'No' }}<br />
              <strong>Added At:</strong> {{ item.addedAt | date: 'medium' }}
            </p>
          </div>
          <div class="card-footer d-flex flex-column gap-2">
            <a [href]="item.spotifyUrl" target="_blank" class="btn btn-outline-primary btn-sm w-100">
              Listen on Spotify
            </a>
            <a [href]="item.albumImageUrl" target="_blank" class="btn btn-outline-secondary btn-sm w-100">
              View Album Image
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- No Items Found Message -->
    <div *ngIf="!isFetching && currentItems.length === 0 && showPlaylistItems" class="text-center mt-5">
      <p>No playlist items found.</p>
    </div>

    <!-- Progress Bar Section -->
    <div class="progress-container mt-4" *ngIf="showLog">
      <div class="d-flex justify-content-between mb-1">
        <small>{{ processedItems }} of {{ totalItems }} tracks processed</small>
        <small>{{ progress }}%</small>
      </div>

      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          [style.width]="progress + '%'"
          attr.aria-valuenow="{{ progress }}"
          aria-valuemin="0"
          aria-valuemax="100">
        </div>
      </div>

      <div class="mt-2">
        <p class="text-center">
          {{ isProcessing ? 'Processing your playlist...' : 'Completed!' }}
        </p>
      </div>
    </div>

    <!-- Log Viewer Component -->
    <div class="log-section" *ngIf="showLog">
      <app-log-viewer
        [logEntries]="logEntries"
        [playlistName]="playlistName"
        [isProcessing]="isProcessing"
        (filterChanged)="onFilterChanged($event)">
      </app-log-viewer>
    </div>
  </div>

  <footer class="text-center mt-5">
    <p>Developed by <a href="https://github.com/primetime43" target="_blank">primetime43</a></p>
    <p>Transfer your Spotify playlists to Apple Music easily with Spotle</p>
  </footer>
</div>
