<div class="container mt-5">
  <!-- Card for Fetching Favorites -->
  <div class="card bg-dark text-white mb-4 shadow-lg">
    <div class="card-body text-center">
      <h3 class="card-title mb-4">Fetch Your Favorite Songs</h3>
      <button class="btn btn-primary btn-lg me-2 mb-3" (click)="fetchAppleMusicFavorites()" [disabled]="isLoading">
        <i class="fa-brands fa-apple me-1"></i> Fetch Apple Music Favorites
      </button>
      <button class="btn btn-success btn-lg mb-3" (click)="fetchSpotifyFavorites()" [disabled]="isLoading">
        <i class="fa-brands fa-spotify me-1"></i> Fetch Spotify Favorites
      </button>
    </div>
  </div>

  <!-- Loading Indicator -->
  <div *ngIf="isLoading" class="text-center mt-4">
    <div class="spinner-border text-primary mb-3" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p>Fetching your favorites...</p>
  </div>

  <!-- Display the dynamic title and table -->
  <div *ngIf="!isLoading && favoriteSongs.length > 0" class="card bg-dark text-white shadow-lg mb-4">
    <div class="card-body">
      <h2 class="card-title text-center">{{ source }} Favorites</h2>
      <div class="table-responsive mt-4">
        <table class="table table-striped table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Song</th>
              <th>Artist</th>
              <th>Album</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let song of favoriteSongs; let i = index">
              <td>{{ i + 1 }}</td>
              <!-- Apple Music Song Data -->
              <ng-container *ngIf="song.attributes; else spotifyTemplate">
                <td>{{ song.attributes.name }}</td>
                <td>{{ song.attributes.artistName }}</td>
                <td>{{ song.attributes.albumName }}</td>
              </ng-container>
              <!-- Spotify Song Data -->
              <ng-template #spotifyTemplate>
                <td>{{ song.track.name }}</td>
                <td>{{ song.track.artists[0]?.name }}</td>
                <td>{{ song.track.album.name }}</td>
              </ng-template>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Export to Excel Button -->
      <div class="text-center mt-3">
        <button class="btn btn-warning btn-lg" (click)="exportFavoritesToExcel()" [disabled]="isLoading">
          <i class="fas fa-file-excel me-1"></i> Export to Excel
        </button>
      </div>
    </div>
  </div>

  <!-- No Favorites Found Message -->
  <div *ngIf="!isLoading && favoriteSongs.length === 0 && fetched" class="card bg-dark text-white text-center p-4 shadow-lg">
    <p>No favorite songs found.</p>
  </div>
</div>
