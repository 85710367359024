<!-- src/app/api-endpoint-testing/api-endpoint-testing.component.html -->

<div class="api-testing-container">
  <h1>API Endpoint Testing</h1>

  <!-- **Apple Music API Endpoint Testing Section** -->
  <div class="accordion-section">
    <button class="accordion-toggle" (click)="toggleAppleMusicSection()">
      <span>{{ isAppleMusicExpanded ? '▼' : '▶' }}</span> Apple Music API Endpoint Testing
    </button>
    <div *ngIf="isAppleMusicExpanded" class="accordion-content">
      <!-- **Test Get Catalog Song Endpoint** -->
      <section class="api-section">
        <h2>Get Catalog Song
          <span class="api-info">
            (Function: <code>getCatalogSong</code>, Endpoint: <code>/api/apple-music/songs/:songId</code>)
          </span>
        </h2>
        <form (ngSubmit)="fetchCatalogSong()">
          <div class="form-group">
            <label for="storefrontSong">Storefront:</label>
            <input
              id="storefrontSong"
              type="text"
              [(ngModel)]="storefrontSong"
              name="storefrontSong"
              required
              placeholder="e.g., us"
            />
          </div>
          <div class="form-group">
            <label for="songId">Song ID:</label>
            <input
              id="songId"
              type="text"
              [(ngModel)]="songId"
              name="songId"
              required
              placeholder="Enter Song ID"
            />
          </div>
          <button type="submit" [disabled]="isLoadingSong">Fetch Song</button>
        </form>

        <div *ngIf="isLoadingSong" class="loading">
          <p>Loading song details...</p>
        </div>

        <div *ngIf="songError" class="error">
          <p>{{ songError }}</p>
        </div>

        <div *ngIf="songData" class="response">
          <h3>Song Details:</h3>
          <!-- Use highlight directive -->
          <pre><code [highlight]="songData | json" language="json"></code></pre>
        </div>
      </section>

      <hr />

      <!-- **Test Get Catalog Playlist Details Endpoint** -->
      <section class="api-section">
        <h2>Get Catalog Playlist Details
          <span class="api-info">
            (Function: <code>getCatalogPlaylistDetails</code>, Endpoint: <code>/api/apple-music/playlist/:playlistId</code>)
          </span>
        </h2>
        <form (ngSubmit)="fetchCatalogPlaylistDetails()">
          <div class="form-group">
            <label for="storefrontPlaylist">Storefront:</label>
            <input
              id="storefrontPlaylist"
              type="text"
              [(ngModel)]="storefrontPlaylist"
              name="storefrontPlaylist"
              required
              placeholder="e.g., us"
            />
          </div>
          <div class="form-group">
            <label for="playlistId">Playlist ID:</label>
            <input
              id="playlistId"
              type="text"
              [(ngModel)]="playlistId"
              name="playlistId"
              required
              placeholder="Enter Playlist ID"
            />
          </div>
          <button type="submit" [disabled]="isLoadingPlaylist">Fetch Playlist</button>
        </form>

        <div *ngIf="isLoadingPlaylist" class="loading">
          <p>Loading playlist details...</p>
        </div>

        <div *ngIf="playlistError" class="error">
          <p>{{ playlistError }}</p>
        </div>

        <div *ngIf="playlistData" class="response">
          <h3>Playlist Details:</h3>
          <!-- Use highlight directive -->
          <pre><code [highlight]="playlistData | json" language="json"></code></pre>
        </div>
      </section>

      <hr />

      <!-- **Test Get Catalog Playlist Tracks Endpoint** -->
      <section class="api-section">
        <h2>Get Catalog Playlist Tracks
          <span class="api-info">
            (Function: <code>getCatalogPlaylistTracks</code>, Endpoint: <code>/v1/catalog/:storefront/playlists/:playlistId/tracks?limit=:limit&offset=:offset</code>)
          </span>
        </h2>
        <form (ngSubmit)="fetchCatalogPlaylistTracks()">
          <div class="form-group">
            <label for="storefrontTracks">Storefront:</label>
            <input
              id="storefrontTracks"
              type="text"
              [(ngModel)]="storefrontTracks"
              name="storefrontTracks"
              required
              placeholder="e.g., us"
            />
          </div>
          <div class="form-group">
            <label for="tracksPlaylistId">Playlist ID:</label>
            <input
              id="tracksPlaylistId"
              type="text"
              [(ngModel)]="tracksPlaylistId"
              name="tracksPlaylistId"
              required
              placeholder="Enter Playlist ID"
            />
          </div>
          <div class="form-group">
            <label for="tracksLimit">Limit:</label>
            <input
              id="tracksLimit"
              type="number"
              [(ngModel)]="tracksLimit"
              name="tracksLimit"
              min="1"
              required
            />
          </div>
          <div class="form-group">
            <label for="tracksOffset">Offset:</label>
            <input
              id="tracksOffset"
              type="number"
              [(ngModel)]="tracksOffset"
              name="tracksOffset"
              min="0"
              required
            />
          </div>
          <button type="submit" [disabled]="isLoadingTracks">Fetch Tracks</button>
        </form>

        <div *ngIf="isLoadingTracks" class="loading">
          <p>Loading playlist tracks...</p>
        </div>

        <div *ngIf="tracksError" class="error">
          <p>{{ tracksError }}</p>
        </div>

        <div *ngIf="tracksData.length > 0" class="response">
          <h3>Playlist Tracks:</h3>
          <!-- Display tracks using pre and code with highlight directive -->
          <pre><code [highlight]="tracksData | json" language="json"></code></pre>
        </div>
      </section>

      <hr />

      <!-- **Create Playlist Endpoint** -->
      <section class="api-section">
        <h2>Create New Playlist
          <span class="api-info">
            (Function: <code>createPlaylist</code>, Endpoint: <code>/api/playlist/create/:name</code>)
          </span>
        </h2>
        <form (ngSubmit)="createNewPlaylist()">
          <div class="form-group">
            <label for="newPlaylistName">Playlist Name:</label>
            <input
              id="newPlaylistName"
              type="text"
              [(ngModel)]="newPlaylistName"
              name="newPlaylistName"
              required
              placeholder="Enter New Playlist Name"
            />
          </div>
          <button type="submit" [disabled]="isCreatingPlaylist">Create Playlist</button>
        </form>

        <div *ngIf="isCreatingPlaylist" class="loading">
          <p>Creating playlist...</p>
        </div>

        <div *ngIf="createPlaylistError" class="error">
          <p>{{ createPlaylistError }}</p>
        </div>

        <div *ngIf="createPlaylistSuccess" class="response">
          <h3>Playlist Created Successfully!</h3>
          <p>Playlist ID: {{ createdPlaylistId }}</p>
        </div>
      </section>

      <hr />

      <!-- **Add Track to Playlist Endpoint** -->
      <section class="api-section">
        <h2>Add Track to Playlist
          <span class="api-info">
            (Function: <code>addTrackToPlaylist</code>, Endpoint: <code>/api/playlist/:playlistId/add-track/:trackId</code>)
          </span>
        </h2>
        <form (ngSubmit)="addTrackToPlaylist()">
          <div class="form-group">
            <label for="addTrackPlaylistId">Playlist ID:</label>
            <input
              id="addTrackPlaylistId"
              type="text"
              [(ngModel)]="addTrackPlaylistId"
              name="addTrackPlaylistId"
              required
              placeholder="Enter Playlist ID"
            />
          </div>
          <div class="form-group">
            <label for="addTrackId">Track ID:</label>
            <input
              id="addTrackId"
              type="text"
              [(ngModel)]="addTrackId"
              name="addTrackId"
              required
              placeholder="Enter Track ID"
            />
          </div>
          <button type="submit" [disabled]="isAddingTrack">Add Track</button>
        </form>

        <div *ngIf="isAddingTrack" class="loading">
          <p>Adding track to playlist...</p>
        </div>

        <div *ngIf="addTrackError" class="error">
          <p>{{ addTrackError }}</p>
        </div>

        <div *ngIf="addTrackSuccess" class="response">
          <h3>Track Added Successfully!</h3>
        </div>
      </section>

      <hr />

      <!-- **Get User Playlists Endpoint** -->
      <section class="api-section">
        <h2>Get User Playlists
          <span class="api-info">
            (Function: <code>getUserPlaylists</code>, Endpoint: <code>/v1/me/library/playlists</code>)
          </span>
        </h2>
        <button (click)="getUserPlaylists()" [disabled]="isLoadingUserPlaylists">Fetch User Playlists</button>

        <div *ngIf="isLoadingUserPlaylists" class="loading">
          <p>Loading user playlists...</p>
        </div>

        <div *ngIf="userPlaylistsError" class="error">
          <p>{{ userPlaylistsError }}</p>
        </div>

        <div *ngIf="userPlaylists.length > 0" class="response">
          <h3>User Playlists:</h3>
          <ul>
            <li *ngFor="let playlist of userPlaylists" class="playlist-item">
              <p><strong>Name:</strong> {{ playlist.attributes.name }}</p>
              <p><strong>Description:</strong> {{ playlist.attributes.description }}</p>
              <p><strong>Track Count:</strong> {{ playlist.attributes.trackCount }}</p>
              <p><strong>Artwork:</strong></p>
              <img
                [src]="getFormattedArtworkUrl(playlist.attributes.artwork.url)"
                [alt]="playlist.attributes.name + ' Artwork'"
                class="artwork"
              />
              <hr />
            </li>
          </ul>
          <!-- Alternatively, use highlight directive to display raw JSON -->
          <!--
          <pre><code [highlight]="userPlaylists | json" language="json"></code></pre>
          -->
        </div>
      </section>

      <hr />

      <!-- **Get Library Playlist Details Endpoint** -->
      <section class="api-section">
        <h2>Get Library Playlist Details
          <span class="api-info">
            (Function: <code>getLibraryPlaylistDetails</code>, Endpoint: <code>/v1/me/library/playlists/:playlistId</code>)
          </span>
        </h2>
        <form (ngSubmit)="getLibraryPlaylistDetails()">
          <div class="form-group">
            <label for="libraryPlaylistId">Playlist ID:</label>
            <input
              id="libraryPlaylistId"
              type="text"
              [(ngModel)]="libraryPlaylistId"
              name="libraryPlaylistId"
              required
              placeholder="Enter Playlist ID"
            />
          </div>
          <button type="submit" [disabled]="isLoadingLibraryPlaylist">Fetch Playlist Details</button>
        </form>

        <div *ngIf="isLoadingLibraryPlaylist" class="loading">
          <p>Loading library playlist details...</p>
        </div>

        <div *ngIf="libraryPlaylistError" class="error">
          <p>{{ libraryPlaylistError }}</p>
        </div>

        <div *ngIf="libraryPlaylistData" class="response">
          <h3>Library Playlist Details:</h3>
          <!-- Use highlight directive -->
          <pre><code [highlight]="libraryPlaylistData | json" language="json"></code></pre>
        </div>
      </section>

      <hr />

      <!-- **Get Library Playlist Tracks Endpoint** -->
      <section class="api-section">
        <h2>Get Library Playlist Tracks
          <span class="api-info">
            (Function: <code>getLibraryPlaylistTracks</code>, Endpoint: <code>/v1/me/library/playlists/:playlistId/tracks?limit=:limit&offset=:offset</code>)
          </span>
        </h2>
        <form (ngSubmit)="fetchLibraryPlaylistTracks()">
          <div class="form-group">
            <label for="libraryTracksPlaylistId">Playlist ID:</label>
            <input
              id="libraryTracksPlaylistId"
              type="text"
              [(ngModel)]="libraryTracksPlaylistId"
              name="libraryTracksPlaylistId"
              required
              placeholder="Enter Playlist ID"
            />
          </div>
          <div class="form-group">
            <label for="libraryTracksLimit">Limit:</label>
            <input
              id="libraryTracksLimit"
              type="number"
              [(ngModel)]="libraryTracksLimit"
              name="libraryTracksLimit"
              min="1"
              required
            />
          </div>
          <div class="form-group">
            <label for="libraryTracksOffset">Offset:</label>
            <input
              id="libraryTracksOffset"
              type="number"
              [(ngModel)]="libraryTracksOffset"
              name="libraryTracksOffset"
              min="0"
              required
            />
          </div>
          <button type="submit" [disabled]="isLoadingLibraryTracks">Fetch Tracks</button>
        </form>

        <div *ngIf="isLoadingLibraryTracks" class="loading">
          <p>Loading library playlist tracks...</p>
        </div>

        <div *ngIf="libraryTracksError" class="error">
          <p>{{ libraryTracksError }}</p>
        </div>

        <div *ngIf="libraryTracksData.length > 0" class="response">
          <h3>Library Playlist Tracks:</h3>
          <!-- Display tracks using pre and code with highlight directive -->
          <pre><code [highlight]="libraryTracksData | json" language="json"></code></pre>
        </div>
      </section>
    </div>

    <hr />

    <!-- **Spotify API Endpoint Testing Section** -->
    <div class="accordion-section">
      <button class="accordion-toggle" (click)="toggleSpotifySection()">
        <span>{{ isSpotifyExpanded ? '▼' : '▶' }}</span> Spotify API Endpoint Testing
      </button>
      <div *ngIf="isSpotifyExpanded" class="accordion-content">
        <!-- **Test Get Spotify Playlist Details Endpoint** -->
        <section class="api-section">
          <h2>
            Get Spotify Playlist Details
            <span class="api-info">
              (Function: <code>getPlaylistDetails</code>, Endpoint: <code>/v1/playlists/:playlistId</code>)
            </span>
          </h2>
          <form (ngSubmit)="fetchSpotifyPlaylistDetails()">
            <div class="form-group">
              <label for="spotifyPlaylistId">Playlist ID:</label>
              <input
                id="spotifyPlaylistId"
                type="text"
                [(ngModel)]="spotifyPlaylistId"
                name="spotifyPlaylistId"
                required
                placeholder="Enter Playlist ID"
              />
            </div>
            <button type="submit" [disabled]="isLoadingSpotifyPlaylist">Fetch Playlist</button>
          </form>

          <div *ngIf="isLoadingSpotifyPlaylist" class="loading">
            <p>Loading Spotify playlist details...</p>
          </div>

          <div *ngIf="spotifyPlaylistError" class="error">
            <p>{{ spotifyPlaylistError }}</p>
          </div>

          <div *ngIf="spotifyPlaylistData" class="response">
            <h3>Spotify Playlist Details:</h3>
            <!-- Use highlight directive -->
            <pre><code [highlight]="spotifyPlaylistData | json" language="json"></code></pre>
          </div>
        </section>

        <hr />

        <!-- **Test Get Spotify Playlist Tracks Endpoint** -->
        <section class="api-section">
          <h2>
            Get Spotify Playlist Tracks
            <span class="api-info">
              (Function: <code>getPlaylistTracks</code>, Endpoint: <code>/v1/playlists/:playlistId/tracks?limit=:limit&offset=:offset</code>)
            </span>
          </h2>
          <form (ngSubmit)="fetchSpotifyPlaylistTracks()">
            <div class="form-group">
              <label for="spotifyTracksPlaylistId">Playlist ID:</label>
              <input
                id="spotifyTracksPlaylistId"
                type="text"
                [(ngModel)]="spotifyTracksPlaylistId"
                name="spotifyTracksPlaylistId"
                required
                placeholder="Enter Playlist ID"
              />
            </div>
            <div class="form-group">
              <label for="spotifyTracksLimit">Limit:</label>
              <input
                id="spotifyTracksLimit"
                type="number"
                [(ngModel)]="spotifyTracksLimit"
                name="spotifyTracksLimit"
                min="1"
                required
              />
            </div>
            <div class="form-group">
              <label for="spotifyTracksOffset">Offset:</label>
              <input
                id="spotifyTracksOffset"
                type="number"
                [(ngModel)]="spotifyTracksOffset"
                name="spotifyTracksOffset"
                min="0"
                required
              />
            </div>
            <button type="submit" [disabled]="isLoadingSpotifyTracks">Fetch Tracks</button>
          </form>

          <div *ngIf="isLoadingSpotifyTracks" class="loading">
            <p>Loading Spotify playlist tracks...</p>
          </div>

          <div *ngIf="spotifyTracksError" class="error">
            <p>{{ spotifyTracksError }}</p>
          </div>

          <div *ngIf="spotifyTracksData.length > 0" class="response">
            <h3>Spotify Playlist Tracks:</h3>
            <!-- Display tracks using pre and code with highlight directive -->
            <pre><code [highlight]="spotifyTracksData | json" language="json"></code></pre>
          </div>
        </section>

        <hr />

        <!-- **Create Spotify Playlist Endpoint** -->
        <section class="api-section">
          <h2>
            Create New Spotify Playlist
            <span class="api-info">
              (Function: <code>createPlaylist</code>, Endpoint: <code>/v1/users/:user_id/playlists</code>)
            </span>
          </h2>
          <form (ngSubmit)="createNewSpotifyPlaylist()">
            <div class="form-group">
              <label for="spotifyNewPlaylistName">Playlist Name:</label>
              <input
                id="spotifyNewPlaylistName"
                type="text"
                [(ngModel)]="spotifyNewPlaylistName"
                name="spotifyNewPlaylistName"
                required
                placeholder="Enter New Playlist Name"
              />
            </div>
            <button type="submit" [disabled]="isCreatingSpotifyPlaylist">Create Playlist</button>
          </form>

          <div *ngIf="isCreatingSpotifyPlaylist" class="loading">
            <p>Creating Spotify playlist...</p>
          </div>

          <div *ngIf="spotifyCreatePlaylistError" class="error">
            <p>{{ spotifyCreatePlaylistError }}</p>
          </div>

          <div *ngIf="spotifyCreatePlaylistSuccess" class="response">
            <h3>Spotify Playlist Created Successfully!</h3>
            <p>Playlist ID: {{ spotifyCreatedPlaylistId }}</p>
          </div>
        </section>

        <hr />

        <!-- **Add Track to Spotify Playlist Endpoint** -->
        <section class="api-section">
          <h2>
            Add Track to Spotify Playlist
            <span class="api-info">
              (Function: <code>addTrackToPlaylist</code>, Endpoint: <code>/v1/playlists/:playlistId/tracks</code>)
            </span>
          </h2>
          <form (ngSubmit)="addTrackToSpotifyPlaylist()">
            <div class="form-group">
              <label for="spotifyAddTrackPlaylistId">Playlist ID:</label>
              <input
                id="spotifyAddTrackPlaylistId"
                type="text"
                [(ngModel)]="spotifyAddTrackPlaylistId"
                name="spotifyAddTrackPlaylistId"
                required
                placeholder="Enter Playlist ID"
              />
            </div>
            <div class="form-group">
              <label for="spotifyAddTrackId">Track ID:</label>
              <input
                id="spotifyAddTrackId"
                type="text"
                [(ngModel)]="spotifyAddTrackId"
                name="spotifyAddTrackId"
                required
                placeholder="Enter Track ID"
              />
            </div>
            <button type="submit" [disabled]="isAddingSpotifyTrack">Add Track</button>
          </form>

          <div *ngIf="isAddingSpotifyTrack" class="loading">
            <p>Adding track to Spotify playlist...</p>
          </div>

          <div *ngIf="spotifyAddTrackError" class="error">
            <p>{{ spotifyAddTrackError }}</p>
          </div>

          <div *ngIf="spotifyAddTrackSuccess" class="response">
            <h3>Track Added Successfully to Spotify Playlist!</h3>
          </div>
        </section>

        <hr />

        <!--Get Spotify Track Details Endpoint** -->
        <section class="api-section">
          <h2>
            Get Spotify Track Details
            <span class="api-info">
              (Function: <code>getTrack</code>, Endpoint: <code>/v1/tracks/:id</code>)
            </span>
          </h2>
          <form (ngSubmit)="fetchSpotifyTrack()">
            <div class="form-group">
              <label for="spotifyTrackId">Track ID:</label>
              <input
                id="spotifyTrackId"
                type="text"
                [(ngModel)]="spotifyTrackId"
                name="spotifyTrackId"
                required
                placeholder="Enter Track ID"
              />
            </div>
            <button type="submit" [disabled]="isLoadingSpotifyTrack">Fetch Track</button>
          </form>

          <div *ngIf="isLoadingSpotifyTrack" class="loading">
            <p>Loading track details...</p>
          </div>

          <div *ngIf="spotifyTrackError" class="error">
            <p>{{ spotifyTrackError }}</p>
          </div>

          <div *ngIf="spotifyTrackData" class="response">
            <h3>Track Details:</h3>
            <!-- Use highlight directive -->
            <pre><code [highlight]="spotifyTrackData | json" language="json"></code></pre>
          </div>
        </section>
      </div>
    </div>
</div>
