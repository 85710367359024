import { Component, OnInit } from '@angular/core';
import { AuthStatusService } from './services/auth-status.service';
import { ToastMessagesService } from './services/toast-messages.service';
import { Router } from '@angular/router';
import { ConfigLoaderService } from './services/config-loader.service';
import { AppPublicConfig } from './interfaces/app-config.interface';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spotle-playlist-transfer';
  isMusicUserLoggedIn = false;
  isSpotifyUserLoggedIn = false;
  appConfig: AppPublicConfig | null = null;

  constructor(
    private authStatusService: AuthStatusService,
    private configLoaderService: ConfigLoaderService,
    private toastService: ToastMessagesService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (environment.googleAnalyticsId) {
      this.loadGoogleAnalytics();
    }

    this.configLoaderService.loadAppConfig().subscribe(config => {
      console.log('App configuration loaded successfully:', config);
      this.appConfig = config; // Store the loaded configuration
    });
    this.authStatusService.isMusicUserLoggedIn$.subscribe(status => {
      this.isMusicUserLoggedIn = status;
    });
    this.authStatusService.isSpotifyUserLoggedIn$.subscribe(status => {
      this.isSpotifyUserLoggedIn = status;
    });
  }

  loadGoogleAnalytics() {
    // Add the external Google Analytics script (gtag.js)
    const externalScript = document.createElement('script');
    externalScript.async = true;
    externalScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
    document.head.appendChild(externalScript);

    // Add the inline script to initialize gtag and configure Google Analytics
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsId}');
    `;
    document.head.appendChild(inlineScript);
  }

  async handleClick(isLoggedIn: boolean, route: string, source: string) {
    if (isLoggedIn) {
      this.router.navigate([route]);
    } else {
      this.toastService.presentToast('Please log in to ' + source + ' to access this feature.', 'danger', 2000);
    }
  }
}
