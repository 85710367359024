<div class="landing-container">
  <header class="header">
    <h1>Spotle - Login</h1>
    <p>Please login with your Spotify account to continue</p>
  </header>

  <div class="features">
    <div class="feature" *ngIf="!userProfile">
      <button (click)="login()" class="btn">Login with Spotify</button>
    </div>
    <div class="feature" *ngIf="userProfile">
      <h2>Welcome, {{ userProfile?.display_name }}</h2>
      <h3>Your Playlists:</h3>
      <ul>
        <li *ngFor="let playlist of playlists?.items">
          {{ playlist.name }}
        </li>
      </ul>
    </div>
  </div>

  <footer class="footer">
    <p>Developed by <a href="https://github.com/primetime43" target="_blank">primetime43</a></p>
    <p>Transfer your Apple Music playlists to Spotify easily with Spotle</p>
  </footer>
</div>
