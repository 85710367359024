import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { SpotifyAuthService } from './spotify-auth.service';
import { TrackService } from './confirm-track.service';
import { UtilsService } from '../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
  private spotifyApiUrl = 'https://api.spotify.com/v1';
  private accessToken: string = localStorage.getItem('spotify_token') ?? '';

  constructor(
    private http: HttpClient,
    private spotifyAuthService: SpotifyAuthService,
    private trackService: TrackService,
    private utilsService: UtilsService
  ) { }

  /**
   * Generates the HTTP headers required for Spotify API requests, including the authorization token.
   * @returns HttpHeaders containing the Authorization and Content-Type headers.
   */
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.accessToken}`,
      'Content-Type': 'application/json'
    });
  }

  /**
   * Ensures that the access token is valid by retrieving a new one if needed.
   * This method updates the stored access token in the service and local storage.
   * @returns An Observable containing the valid access token.
   */
  private ensureValidToken(): Observable<string> {
    return this.spotifyAuthService.getValidAccessToken().pipe(
      tap(token => {
        this.accessToken = token;  // Update the accessToken in the service
        localStorage.setItem('spotify_token', token);
      })
    );
  }

  /**
   * Checks whether the user is signed in to Spotify by verifying the presence of an access token.
   * @returns True if the user is signed in, false otherwise.
   */
  private isUserSignedIn(): boolean {
    return !!this.accessToken;
  }

  /**
   * Fetches the details of a Spotify playlist by its ID.
   * @param playlistId The ID of the playlist to fetch details for.
   * @returns An Observable containing the playlist details.
   */
  getPlaylistDetails(playlistId: string): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        return this.http.get(`${this.spotifyApiUrl}/playlists/${playlistId}`, { headers }).pipe(
          catchError(error => {
            console.error('Error fetching playlist details:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Fetches the tracks of a Spotify playlist by its ID with pagination support.
   * @param playlistId The ID of the playlist to fetch tracks for.
   * @param limit The number of tracks to retrieve per request.
   * @param offset The offset index from where to start fetching tracks.
   * @returns An Observable containing the playlist tracks.
   */
  getPlaylistTracks(playlistId: string, limit: number, offset: number): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        return this.http.get(`${this.spotifyApiUrl}/playlists/${playlistId}/tracks?limit=${limit}&offset=${offset}`, { headers }).pipe(
          tap(response => {
            //console.log('getPlaylistTracks response:', response); // Log the response for debugging
          }),
          catchError(error => {
            console.error('Error fetching playlist tracks:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Creates a new Spotify playlist for the current user.
   * @param name The name of the new playlist.
   * @param description An optional description for the playlist.
   * @param isPublic Whether the playlist should be public or private.
   * @returns An Observable containing the response from the playlist creation request.
   */
  createPlaylist(name: string, description: string = '', isPublic: boolean = false): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.getUserInfo().pipe(
      switchMap(userInfo => {
        const userId = userInfo?.id ?? '';
        const url = `${this.spotifyApiUrl}/users/${userId}/playlists`;

        const body = {
          name,
          description: 'Playlist created by Spotle, developed by primetime43.',
          public: isPublic
        };

        const headers = this.getHeaders();

        return this.http.post(url, body, { headers }).pipe(
          map(response => response),
          catchError(error => {
            console.error('Error creating playlist:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Searches for a specific track on Spotify by track name, artist name, and album name.
   * The method attempts to find the best match based on multiple criteria and calculates a confidence score.
   * @param trackName The name of the track to search for.
   * @param artistName The name of the artist who performed the track.
   * @param albumName The name of the album the track belongs to.
   * @param isExplicit Whether the track is explicit.
   * @param trackNumber The track number in the album.
   * @returns An Observable containing the matched track and its confidence score, or null if no match is found.
   */
  searchTrack(
    trackName: string,
    artistName: string,
    albumName: string,
    isExplicit: boolean,
    trackNumber: number,
    askForTrackConfirmation: boolean
  ): Observable<{ track: any, confidence: number } | null | Promise<{ track: any, confidence: number } | null>> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    const normalizedTrackName = this.utilsService.normalizeString(trackName);
    const normalizedAlbumName = this.utilsService.normalizeString(albumName);
    const normalizedArtistName = this.utilsService.normalizeString(artistName);

    const searchTerm = `${normalizedTrackName} ${normalizedArtistName}`;
    const url = `${this.spotifyApiUrl}/search?q=${encodeURIComponent(searchTerm)}&type=track&limit=10`;
    const headers = this.getHeaders();

    return this.http.get(url, { headers }).pipe(
      map(response => {
        const results = (response as any).tracks.items;
        if (!results || results.length === 0) {
          throw new Error('No tracks found');
        }

        let confidence = 1.0;

        let filteredResults = results.filter((track: any) => {
          const trackNameContains = this.utilsService.normalizeString(track.name).includes(normalizedTrackName);
          const albumNameContains = this.utilsService.normalizeString(track.album.name).includes(normalizedAlbumName);
          const explicitMatch = track.explicit === isExplicit;
          const artistMatch = this.utilsService.normalizeString(track.artists.map((a: any) => a.name).join(', ')).includes(normalizedArtistName);

          return trackNameContains && albumNameContains && explicitMatch && artistMatch;
        });

        if (filteredResults.length > 0) {
          return { track: filteredResults[0], confidence };
        }

        confidence -= 0.2;
        filteredResults = results.filter((track: any) => {
          const albumContains = this.utilsService.normalizeString(track.album.name).includes(normalizedAlbumName);
          const explicitMatch = track.explicit === isExplicit;
          return albumContains && explicitMatch;
        });

        if (filteredResults.length > 0) {
          return { track: filteredResults[0], confidence };
        }

        confidence -= 0.3;
        filteredResults = results.filter((track: any) => {
          const trackWords = normalizedTrackName.split(' ');
          const trackNameContainsAnyWord = trackWords.some(word => this.utilsService.normalizeString(track.name).includes(word));
          const explicitMatch = track.explicit === isExplicit;
          return trackNameContainsAnyWord && explicitMatch;
        });

        if (filteredResults.length > 0) {
          return { track: filteredResults[0], confidence };
        }

        // If it gets here, search again and return the first result and ask the user if this is a match
        console.warn(`No match found for track: ${trackName} by ${artistName} on album ${albumName}` + ' asking user ' + askForTrackConfirmation);

        if (askForTrackConfirmation) {
          // Performing a simpler search without normalization to get the first result
          return this.performFallbackSearch(trackName, artistName, albumName, isExplicit, trackNumber);
        }

        return null;
      }),
      catchError(error => {
        console.error('Error searching track:', error);
        return throwError(error);
      })
    );
  }

  private async performFallbackSearch(
    trackName: string,
    artistName: string,
    albumName: string,
    isExplicit: boolean,
    trackNumber: number
  ): Promise<{ track: any, confidence: number } | null> {
    const userToken = this.accessToken;
    const fallbackUrl = `${this.spotifyApiUrl}/search?q=${encodeURIComponent(`${trackName} ${artistName}`)}&type=track&limit=1`;
    const headers = this.getHeaders();

    try {
      const fallbackResponse = await this.http.get(fallbackUrl, { headers }).toPromise();
      const fallbackResults = (fallbackResponse as any).tracks.items;

      if (fallbackResults && fallbackResults.length > 0) {
        // Return the first result and ask the user if this is a match
        const firstResult = fallbackResults[0];
        console.log('Fallback first result:', firstResult);

        const userConfirmed = await this.trackService.confirmWithUser(
          firstResult.name,
          firstResult.artists.map((a: any) => a.name).join(', '),
          firstResult.album.name,
          trackName, // Pass original track name searched
          artistName, // Pass original artist name searched
          albumName  // Pass original album name searched
        );

        if (userConfirmed) {
          return { track: firstResult, confidence: 0.5 }; // Assuming a lower confidence since it's a fallback
        } else {
          return null;
        }
      }
      return null;
    } catch (error) {
      console.error('Error during fallback search:', error);
      return null;
    }
  }


  /**
   * Adds a specific track to a Spotify playlist by the track's Spotify ID.
   * @param playlistId The ID of the playlist to add the track to.
   * @param trackId The ID of the track to add to the playlist.
   * @returns An Observable containing the response from the add track request.
   */
  addTrackToPlaylist(playlistId: string, trackId: string): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    const url = `${this.spotifyApiUrl}/playlists/${playlistId}/tracks`;
    const body = { uris: [`spotify:track:${trackId}`] };
    const headers = this.getHeaders();
    return this.http.post(url, body, { headers }).pipe(
      catchError(error => {
        console.error('Error adding track to playlist:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Fetches the current user's profile information from Spotify.
   * @returns An Observable containing the user's profile information.
   */
  getUserInfo(): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        return this.http.get(`${this.spotifyApiUrl}/me`, { headers }).pipe(
          map(response => response),
          catchError(error => {
            console.error('Error fetching user info:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Loads the current user's profile information from Spotify.
   * @returns An Observable containing the user's profile information.
   */
  loadUserProfile(): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        return this.http.get(`${this.spotifyApiUrl}/me`, { headers }).pipe(
          catchError(error => {
            console.error('Error fetching user profile:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Loads the playlists associated with the current Spotify user.
   * @returns An Observable containing the user's playlists.
   */
  loadPlaylists(): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        return this.http.get(`${this.spotifyApiUrl}/me/playlists`, { headers }).pipe(
          catchError(error => {
            console.error('Error fetching playlists:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Fetches the favorite (saved) songs of the current Spotify user.
   * @param limit The number of songs to retrieve per request (max 50).
   * @param offset The offset from which to start retrieving saved songs.
   * @returns An Observable containing the user's saved songs.
   */
  getFavoriteSongs(limit: number = 50, offset: number = 0): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        const url = `${this.spotifyApiUrl}/me/tracks?limit=${limit}&offset=${offset}`;
        console.log('headers', headers);
        console.log('url', url);
        return this.http.get(url, { headers }).pipe(
          map(response => response), // Adjust the structure as needed
          catchError(error => {
            console.error('Error fetching favorite songs:', error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Fetches the details of a specific Spotify track by its ID.
   * @param trackId The ID of the track to fetch details for.
   * @returns An Observable containing the track details.
   */
  getTrack(trackId: string): Observable<any> {
    if (!this.isUserSignedIn()) {
      alert('User is not signed in to Spotify');
      return throwError(() => new Error('User is not signed in to Spotify'));
    }

    return this.ensureValidToken().pipe(
      switchMap(() => {
        const headers = this.getHeaders();
        const url = `${this.spotifyApiUrl}/tracks/${trackId}`;
        return this.http.get(url, { headers }).pipe(
          catchError(error => {
            console.error('Error fetching track details:', error);
            return throwError(error);
          })
        );
      })
    );
  }
}
