import { Component, Input, Output, EventEmitter, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { LogEntry } from '../interfaces/log-entry.interface';
import { LogExportService } from '../services/log-export.service';

@Component({
  selector: 'app-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss']
})
export class LogViewerComponent implements AfterViewChecked {
  @Input() logEntries: LogEntry[] = [];
  @Input() playlistName: string = '';
  @Input() isProcessing: boolean = true;
  @Output() filterChanged = new EventEmitter<string>();

  @ViewChild('logEntriesContainer') private logEntriesContainer!: ElementRef;

  currentFilter: string = 'all';
  searchTerm: string = '';

  constructor(private logExportService: LogExportService) {}

  // Method to filter log entries based on search term and current filter
  filteredLogEntries() {
    return this.logEntries.filter(entry => {
      const matchesFilter = this.filterLogEntry(entry);
      const matchesSearch = this.searchTerm
        ? entry.message.toLowerCase().includes(this.searchTerm.toLowerCase())
        : true;

      return matchesFilter && matchesSearch;
    });
  }

  // Helper method to filter log entries based on the selected filter type
  filterLogEntry(entry: LogEntry): boolean {
    switch (this.currentFilter) {
      case 'success':
        return entry.success;
      case 'error':
        return !entry.success;
      case 'high-confidence':
        return entry.confidence >= 0.8;
      case 'medium-confidence':
        return entry.confidence >= 0.5 && entry.confidence < 0.8;
      case 'low-confidence':
        return entry.confidence > 0 && entry.confidence < 0.5;
      default:
        return true; // 'all' case
    }
  }

  // Set the current filter and emit the change
  setFilter(type: string) {
    this.currentFilter = type;
    this.filterChanged.emit(type);
  }

  // Scroll to the bottom of the log entries
  scrollToBottom(): void {
    if (this.logEntriesContainer) {
      try {
        this.logEntriesContainer.nativeElement.scrollTop = this.logEntriesContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Scroll to bottom failed:', err);
      }
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  // Export log entries to Excel
  exportLog() {
    this.logExportService.exportLogToExcel(this.logEntries, this.playlistName);
  }
}
